// versionDetailsConfig.js
export const VERSION_INFO = Object.freeze({
    number: '1.0.14',
    date: '19th Dec',
    fullVersion: 'Zippie.1.0.14',
    formattedVersion: 'V.1.0.14 · 19th Dec',
    changes: [
      'Updated the educator filter list',
      'Added the ability to upload images to group observations',
      'Improved summary generation logic',
      'Introduced a new observation widget for parents',
      'Added a new Observations Shared with Parents page',
      'Changed observation-related links to buttons in educator summaries',
      'Fixed: selecting prompt in parent summary generation(admin)',
      'Added cohort-wise summary generation functionality',
      'Enabled viewing summaries for each cohort in Observation Summaries',
      // { text: '', highlight: true }
    ]
  })




 


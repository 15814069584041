<template>
  <div class="flex flex-col gap-4">
    <div class="flex justify-between">
      <h5 class="underline font-medium underline-offset-[0.4rem] decoration-2">
        Overview of the week - parent
      </h5>
      <div class="flex gap-2">
        <div class="flex gap-2">
          <button type="button" v-if="!editMode" :disabled="editMode"
            class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium
            rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
            disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700
            dark:text-white dark:hover:bg-neutral-800" @click="enableEdit">
            Edit
          </button>
          <button type="button" v-if="editMode" :disabled="!editMode"
            class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium
            rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
            disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700
            dark:text-white dark:hover:bg-neutral-800" @click="cancelEdit">
            Cancel Edit
          </button>
          <button type="button" v-if="editMode" :disabled="!editMode"
            class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium
            rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
            disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700
            dark:text-white dark:hover:bg-neutral-800" @click="saveEdits">
            Save Changes
          </button>
        </div>
        <ParentGenerateModal modalName="generate-parent-weekly-overview-modal" :today="today" :reloadData="reloadData">
          <template #trigger>
            <button type="button" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium
              rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
              disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700
              dark:text-white dark:hover:bg-neutral-800":data-hs-overlay="`#hs-generate-parent-weekly-overview-modal`">
              Generate
            </button>
          </template>
        </ParentGenerateModal>
      </div>
    </div>
    <p v-if="isSaving">Saving edits... Please wait.</p>
    <p v-if="saveStatus">{{ saveStatus }}</p>
    <template v-if="Object.entries(parentWeeklyDataResource.data?.summaries || {}).length"
      v-for="[cohort, summary] in Object.entries(parentWeeklyDataResource.data.summaries)">
      <div v-if="props.selectedCohort === 'all' || props.selectedCohort == cohort"
        class="rounded bg-[#FFF9E5] whitespace-pre-line text-sm p-3 flex-auto">
        <template v-if="!editMode">
          <div class="flex justify-between pb-2">
            <p class="font-medium text-lg">
              {{ findValueByName(userDetails?.school_info.cohorts, summary.cohort, 'cohort_name') }}
              <span v-if="shareStatus[summary.name]" class="font-normal">(shared with parents)</span>
              <span v-else class="font-normal">(not shared)</span>
            </p>
            <button type="button" v-if="!editMode" :disabled="editMode"
              class="py-2 px-4 inline-flex items-center gap-x-2 text-sm font-medium
              rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
              disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700
              dark:text-white dark:hover:bg-neutral-800" @click="() => openShareConfirmModal(summary.name)">
              <span v-if="!shareStatus[summary.name]">Share</span>
              <span v-else>Undo Share</span>
            </button>
          </div>
          <div v-if="savedSummaries[summary.id]" v-html="getParsedSummary(savedSummaries[summary.id])" class="text-wrap">
          </div>
          <div v-else-if="summary.summary" v-html="getParsedSummary(summary.summary)" class="text-wrap">
          </div>
          <div v-else>
            "Summary not found."
          </div>
        </template>
        <template v-else>
          <div class="flex flex-col gap-2">
            <label :for="`textarea-parent-summary-${cohort}`" class="font-medium px-2">
              {{ findValueByName(userDetails?.school_info.cohorts, summary.cohort, 'cohort_name') }} summary
            </label>
            <textarea :id="`textarea-parent-summary-${cohort}`" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm
              focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" rows="10"
              placeholder="Enter prompt here..." :name="`summary-${cohort}`" v-model="editedSummaries[cohort]"
              ></textarea>
              <!-- :ref="el => textareaRefs.set(cohort, el)"
              @input="() => textareaAutoHeight(cohort)"></textarea> -->
              <!-- ref="promptInputRef" -->
          </div>
        </template>
      </div>
    </template>
    <div v-else class="rounded bg-[#FFF9E5] whitespace-pre-line text-sm p-3 flex-auto">
      No shareable summaries present. Click generate to generate new.
    </div>
    <ShareConfirmModal modalName="parent-share-confirm-modal" :share-status="modalShareStatus"
      :shareFn="shareSummary" :summaryId="summaryToBeShared" />
  </div>
</template>

<script setup>
import { createResource } from 'frappe-ui'
import { findValueByName } from '@/lib/utils';
import { inject, ref, watch } from 'vue';
import ParentGenerateModal from './ParentGenerateModal.vue';
import ShareConfirmModal from './ShareConfirmModal.vue';
import { HSOverlay } from 'preline';

const props = defineProps(['weeklySummary', 'today', 'selectedCohort']);

const userDetails = inject('userDetails');

const parentWeeklyDataResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.common.observations.summary.weekly.api.fetch_ongoing_data_cohorts',
 params: {
  date: props.today,
 },
 auto: true,
});

const reloadData = () => {
  parentWeeklyDataResource.submit({ date: props.today })
}

watch(() => props.today, () => {
  parentWeeklyDataResource.submit({ date: props.today })
})

const editMode = ref(false);
const editedSummaries = ref({})
// const originalSummaries = ref({})

const enableEdit = () => {
  saveStatus.value = '';
  if (editMode.value) 
    return;
  if (!Object.entries(parentWeeklyDataResource.data?.summaries || {}).length)
    return;
  Object.entries(parentWeeklyDataResource.data.summaries).forEach(([cohort, summary]) => {
    // originalSummaries.value[cohort] = summary.summary;
    editedSummaries.value[cohort] = summary.summary;
  });
  editMode.value = true;
}

const cancelEdit = () => {
  if (!editMode.value) 
    return;
  if (!Object.entries(parentWeeklyDataResource.data?.summaries || {}).length)
    return;
  Object.entries(parentWeeklyDataResource.data.summaries).forEach(([cohort, summary]) => {
    // originalSummaries.value[cohort] = summary.summary;
    editedSummaries.value[cohort] = summary.summary;
  });
  editMode.value = false;
}

const summaryEditResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.observation_summaries.api.edit_summaries',
});

const isSaving = ref(false)
const saveStatus = ref('')
const savedSummaries = ref({})

const saveEdits = async () => {
  isSaving.value = true;
  try {
    const summaryList = [] 
    Object.entries(editedSummaries.value || {}).forEach(([cohort, summary]) => {
      const summaryId = parentWeeklyDataResource.data.summaries[cohort].name;
      summaryList.push({ id: summaryId, summary: summary })
    })
    await summaryEditResource.submit({
      edited_summaries: summaryList
    })
    console.log(summaryEditResource)

    if (summaryEditResource.data?.status === 'success') {
      saveStatus.value = `Edited summaries saved.`;
      console.log("Failed edits: ", summaryEditResource.data.failed_summaries);
      summaryList.forEach((summary) => {
        if (!summaryEditResource.data.failed_summaries.includes(summary.id)) {
          savedSummaries.value[summary.id] = summary.summary;
        };
      })
      editMode.value = false;
      parentWeeklyDataResource.submit({date:props.today})
    } else {
      saveStatus.value = "Editing failed.";
    }
  } catch (error) {
    console.error("Summary edit request failed", error);
    saveStatus.value = "Editing failed(request failed).";
  }
  isSaving.value = false;
}

const shareStatus = ref({})

if (parentWeeklyDataResource.data) {
  Object.entries(parentWeeklyDataResource.data?.summaries || {}).forEach(([cohort, summary]) => {
    shareStatus.value[summary.name] = summary.is_shared_with_parents;
  });
}

console.log({shareStatus})

watch(() => parentWeeklyDataResource.data, () => {
  if (parentWeeklyDataResource.fetched) {
    Object.entries(parentWeeklyDataResource.data?.summaries || {}).forEach(([cohort, summary]) => {
      shareStatus.value[summary.name] = summary.is_shared_with_parents;
    });
  }
})

const summaryShareResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.observation_summaries.api.share_summary',
});


const modalShareStatus = ref(1)
const summaryToBeShared = ref('')

const openShareConfirmModal = (summaryName) => {
  modalShareStatus.value = !shareStatus.value[summaryName];
  summaryToBeShared.value = summaryName;
  HSOverlay.open('#hs-parent-share-confirm-modal');
}

// toggles share status
const shareSummary = async (summaryId) => {
  HSOverlay.close('#hs-parent-share-confirm-modal');
  if(!summaryToBeShared.value) return;
  try {
    const newStatus = !shareStatus.value[summaryId]
    await summaryShareResource.submit({
      summary_id: summaryId,
      status: newStatus
    })
    console.log(summaryShareResource)

    if (summaryShareResource.data?.status === 'success') {
      shareStatus.value[summaryId] = newStatus;
    } else {
      console.error("Changing share status failed")
    }
  } catch (error) {
    console.error("Changing share status failed", error);
  }
  summaryToBeShared.value = '';
}

const textareaRefs = new Map();

const textareaAutoHeight = (cohort) => {
  console.log({textareaRefs})
  const textarea = textareaRefs.get(cohort);
  console.log()
  console.log("Textarea ", {textarea})
  if (!textarea) return;

  const windowHeight = window.innerHeight;
  const offsetTop = 2;

  // Reset height to auto to calculate scrollHeight correctly
  textarea.style.height = 'auto';

  // Calculate new height and limit it
  let calculatedHeight = textarea.scrollHeight + offsetTop;
  if (calculatedHeight > windowHeight / 2.5) {
    calculatedHeight = windowHeight / 2.5;
  }

  // Apply the calculated height
  textarea.style.height = `${calculatedHeight}px`;
};

console.log({parentWeeklyDataResource})

const getParsedSummary = (summary) => {
  return summary.replace(/\{\{(.+?)\}\}/g, (match, text) => {
    return `<h3 class="font-medium">${text}</h3>`;
  });
}
</script>
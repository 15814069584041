<template>
 
 <!-- Buttons -->
  <div class="hidden md:flex justify-between items-center w-100% md:sticky md:top-[8.5%] z-5 md:bg-white md:p-2">
   
    <!-- Ordered buttons and divs -->
    <h2 class="flex justify-center hidden md:block text-small md:text-2xl text-black text-center font-medium items-center">
      {{ this.headerMonthYear }}
    </h2>

    <div class="flex justify-between w-[100%] md:w-auto">

      <!--Week navigation button group  -->
      <div class="hidden md:inline-flex rounded-lg shadow-sm mr-2 md:mr-0" style="background-color:rgba(253, 216, 53, 1)">

        <button v-if="viewMode === 'weekly'" @click="goToPreviousWeek" type="button"
          class="py- px-3 md:mr-0 inline-flex items-center gap-x-2 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm font-medium focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm"
          style="background-color:rgba(253, 216, 53, 1)">
          <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M6.93494 0.440056C6.97569 0.480696 7.00801 0.528974 7.03007 0.582126C7.05212 0.635278 7.06348 0.692259 7.06348 0.749806C7.06348 0.807352 7.05212 0.864333 7.03007 0.917485C7.00801 0.970637 6.97569 1.01892 6.93494 1.05956L1.99382 5.99981L6.93494 10.9401C6.97562 10.9807 7.00789 11.029 7.0299 11.0822C7.05192 11.1353 7.06325 11.1923 7.06325 11.2498C7.06325 11.3073 7.05192 11.3643 7.0299 11.4174C7.00789 11.4706 6.97562 11.5189 6.93494 11.5596C6.89427 11.6002 6.84598 11.6325 6.79283 11.6545C6.73968 11.6765 6.68272 11.6879 6.62519 11.6879C6.56767 11.6879 6.51071 11.6765 6.45756 11.6545C6.40441 11.6325 6.35612 11.6002 6.31544 11.5596L1.06544 6.30956C1.0247 6.26892 0.992377 6.22064 0.970321 6.16748C0.948266 6.11433 0.936913 6.05735 0.936913 5.99981C0.936913 5.94226 0.948266 5.88528 0.970321 5.83213C0.992377 5.77897 1.0247 5.7307 1.06544 5.69006L6.31544 0.440056C6.35608 0.399313 6.40436 0.366988 6.45752 0.344932C6.51067 0.322876 6.56765 0.311523 6.62519 0.311523C6.68274 0.311523 6.73972 0.322876 6.79287 0.344932C6.84603 0.366988 6.8943 0.399313 6.93494 0.440056Z"
              fill="#1F2937" stroke="black" stroke-width="0.5" />
          </svg>
        </button>

        <button type="button"
          class="py-1.5 px-1 md:py-2 md:px-3.5 inline-flex items-center gap-x-2 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm font-medium focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm"
          style="background-color:rgba(253, 216, 53, 1)">
          Week
        </button>

        <button v-if="viewMode === 'weekly'" @click="goToNextWeek" type="button"
          class="py- px-3 inline-flex items-center gap-x-2 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm font-medium focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm"
          style="background-color:rgba(253, 216, 53, 1)">
          <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M1.06506 11.5599C1.02431 11.5193 0.991987 11.471 0.969932 11.4179C0.947876 11.3647 0.936523 11.3077 0.936523 11.2502C0.936523 11.1926 0.947876 11.1357 0.969932 11.0825C0.991987 11.0294 1.02431 10.9811 1.06506 10.9404L6.00618 6.00019L1.06506 1.05995C1.02438 1.01927 0.992112 0.970978 0.970097 0.91783C0.948083 0.864683 0.936752 0.80772 0.936752 0.750195C0.936752 0.692669 0.948083 0.635706 0.970097 0.582559C0.992112 0.529412 1.02438 0.481121 1.06506 0.440445C1.10573 0.399768 1.15402 0.367501 1.20717 0.345487C1.26032 0.323472 1.31728 0.312142 1.37481 0.312142C1.43233 0.312142 1.48929 0.323472 1.54244 0.345487C1.59559 0.367501 1.64388 0.399768 1.68456 0.440445L6.93456 5.69044C6.9753 5.73108 7.00762 5.77936 7.02968 5.83252C7.05173 5.88567 7.06309 5.94265 7.06309 6.00019C7.06309 6.05774 7.05173 6.11472 7.02968 6.16787C7.00762 6.22103 6.9753 6.2693 6.93456 6.30994L1.68456 11.5599C1.64392 11.6007 1.59564 11.633 1.54248 11.6551C1.48933 11.6771 1.43235 11.6885 1.37481 11.6885C1.31726 11.6885 1.26028 11.6771 1.20713 11.6551C1.15397 11.633 1.1057 11.6007 1.06506 11.5599Z"
              fill="#1F2937" stroke="black" stroke-width="0.5" />
          </svg>
        </button>
      </div>

      <!-- <h2
        class="flex justify-center md:hidden md:mr-0 md:ml-0 mr-2.5 ml-2.5 block text-small md:text-2xl text-black text-center font-medium items-center">
        {{ this.headerMonthYear }}
      </h2> -->


      <!-- View mode button group -->
      <!-- <nav class=" flex ml-4 hs-tooltip inline-block [--trigger:click] [--placement:right]">
        <button type="button" :style="{
        backgroundColor: viewMode === 'weekly' ? 'black' : 'rgba(253, 216, 53, 1)',
        color: viewMode === 'weekly' ? 'white' : (viewMode === 'custom' ? 'black' : 'inherit')
      }" class="text-xs md:text-s rounded-l-lg md:w-[50px] w-[43px]" @click="toggleViewMode('weekly')">
          Week
        </button>

        <button type="button" :style="{
        backgroundColor: viewMode === 'monthly' ? 'black' : 'rgba(253, 216, 53, 1)',
        color: viewMode === 'monthly' ? 'white' : (viewMode === 'custom' ? 'black' : 'inherit')
      }" class="hs-tooltip-toggle text-xs md:text-s rounded-r-lg md:w-[50px] w-[43px]">
          Day
        </button>
        <span class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-3 px-4 bg-white border text-sm text-gray-600 rounded-lg shadow-md dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400" role="tooltip">
            Day view coming soon! 
          </span>

      </nav> -->


    </div>

  </div>


  <!-- Tool bar  -->                                                <!--md:top-[14.9%]-->
  <div class="flex md:flex-row md:justify-end w-[100%] mb-3 mt-1 md:mt-0 md:mb-0 md:sticky md:top-[] z-20 md:bg-white md:pl-2 md:pr-2">

    <!-- Cohort colours lable container-->
    <!-- <div class="flex w-[100%] md:w-[50%] items-center justify-between md:justify-start"> -->

      
      <!-- colour label preline component -->
      <!-- <div class="hs-accordion-group">
        <div class="hs-accordion" id="hs-basic-heading-three">
          <button
            class="hs-accordion-toggle hs-accordion-active: py-3 inline-flex items-center gap-x-1.5 w-full font-medium focus:outline-none focus:ring-2 focus:ring-transparent text-start rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:hs-accordion-active: dark:text-gray-200 dark:hover:text-gray-400 dark:focus:outline-none dark:focus:text-gray-400"
            aria-controls="hs-basic-collapse-three">
            <svg class="hs-accordion-active:hidden block size-4" xmlns="http://www.w3.org/2000/svg" width="24"
              height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round">
              <path d="M5 12h14" />
              <path d="M12 5v14" />
            </svg>
            <svg class="hs-accordion-active:block hidden size-4" xmlns="http://www.w3.org/2000/svg" width="24"
              height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round">
              <path d="M5 12h14" />
            </svg>

            <div class="w-[22px] h-[18px] mr-0 rounded-[2px] ml-0" style="background-color: rgba(147, 148, 255, 1);">
            </div> <span class="text-sm md:text-base">Bombils</span>

            <div class="w-[22px] h-[18px] mr-0 rounded-[2px] ml-0"
              style="background-color: rgba(73, 201, 229, 1); display: inline-block;"></div>
               <span class="text-sm md:text-base">Batatas</span>

            <div class="w-[22px] h-[18px] mr-0 rounded-[2px] ml-0"
              style="background-color: rgba(234, 148, 255, 1); display: inline-block;"></div> <span
              class="text-sm md:text-base">Bimblis</span>

          </button>
          <div id="hs-basic-collapse-three"
            class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300 mb-2"
            aria-labelledby="hs-basic-heading-three">
            <p class="text-gray-800 dark:text-gray-200">

            <div class="w-[22px] h-[18px] mr-0 rounded-[2px] ml-2"
              style="background-color: rgba(157, 237, 255, 1); display: inline-block;"></div> <span
              class="text-sm md:text-base">BigKids</span>
            <div class="w-[22px] h-[18px] mr-0 rounded-[2px] ml-2"
              style="background-color: rgba(167, 238, 142, 1); display: inline-block;"></div> <span
              class="text-sm md:text-base">Jr Batatas</span>
            <div class="w-[22px] h-[18px] mr-0 rounded-[2px] ml-2"
              style="background-color: rgba(255, 138, 0, 1); display: inline-block;"></div> <span
              class="text-sm md:text-base">Group event</span>

            </p>
          </div>
        </div>
      </div> -->
    <!-- </div> -->

    <!--Week navigation button group  -->
    <div class="md:hidden inline-flex rounded-lg shadow-sm mr-2 md:mr-0" style="background-color:rgba(253, 216, 53, 1)">
        <button @click="goToPreviousWeek" type="button"
          class="py- px-3 md:mr-0 inline-flex items-center gap-x-2 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm font-medium focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm"
          style="background-color:rgba(253, 216, 53, 1)">
          <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M6.93494 0.440056C6.97569 0.480696 7.00801 0.528974 7.03007 0.582126C7.05212 0.635278 7.06348 0.692259 7.06348 0.749806C7.06348 0.807352 7.05212 0.864333 7.03007 0.917485C7.00801 0.970637 6.97569 1.01892 6.93494 1.05956L1.99382 5.99981L6.93494 10.9401C6.97562 10.9807 7.00789 11.029 7.0299 11.0822C7.05192 11.1353 7.06325 11.1923 7.06325 11.2498C7.06325 11.3073 7.05192 11.3643 7.0299 11.4174C7.00789 11.4706 6.97562 11.5189 6.93494 11.5596C6.89427 11.6002 6.84598 11.6325 6.79283 11.6545C6.73968 11.6765 6.68272 11.6879 6.62519 11.6879C6.56767 11.6879 6.51071 11.6765 6.45756 11.6545C6.40441 11.6325 6.35612 11.6002 6.31544 11.5596L1.06544 6.30956C1.0247 6.26892 0.992377 6.22064 0.970321 6.16748C0.948266 6.11433 0.936913 6.05735 0.936913 5.99981C0.936913 5.94226 0.948266 5.88528 0.970321 5.83213C0.992377 5.77897 1.0247 5.7307 1.06544 5.69006L6.31544 0.440056C6.35608 0.399313 6.40436 0.366988 6.45752 0.344932C6.51067 0.322876 6.56765 0.311523 6.62519 0.311523C6.68274 0.311523 6.73972 0.322876 6.79287 0.344932C6.84603 0.366988 6.8943 0.399313 6.93494 0.440056Z"
              fill="#1F2937" stroke="black" stroke-width="0.5" />
          </svg>
        </button>

        <button type="button"
          class="py-1.5 px-1 md:py-2 md:px-3.5 inline-flex items-center gap-x-2 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm font-medium focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm"
          style="background-color:rgba(253, 216, 53, 1)">
          Week
          <!-- {{ moment(datesOfCurrentWeek[0]).format('DD MMM ddd') }}
          {{ moment(datesOfCurrentWeek[4]).format('DD MMM ddd') }} -->
        </button>

        <button @click="goToNextWeek" type="button"
          class="py- px-3 inline-flex items-center gap-x-2 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm font-medium focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm"
          style="background-color:rgba(253, 216, 53, 1)">
          <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M1.06506 11.5599C1.02431 11.5193 0.991987 11.471 0.969932 11.4179C0.947876 11.3647 0.936523 11.3077 0.936523 11.2502C0.936523 11.1926 0.947876 11.1357 0.969932 11.0825C0.991987 11.0294 1.02431 10.9811 1.06506 10.9404L6.00618 6.00019L1.06506 1.05995C1.02438 1.01927 0.992112 0.970978 0.970097 0.91783C0.948083 0.864683 0.936752 0.80772 0.936752 0.750195C0.936752 0.692669 0.948083 0.635706 0.970097 0.582559C0.992112 0.529412 1.02438 0.481121 1.06506 0.440445C1.10573 0.399768 1.15402 0.367501 1.20717 0.345487C1.26032 0.323472 1.31728 0.312142 1.37481 0.312142C1.43233 0.312142 1.48929 0.323472 1.54244 0.345487C1.59559 0.367501 1.64388 0.399768 1.68456 0.440445L6.93456 5.69044C6.9753 5.73108 7.00762 5.77936 7.02968 5.83252C7.05173 5.88567 7.06309 5.94265 7.06309 6.00019C7.06309 6.05774 7.05173 6.11472 7.02968 6.16787C7.00762 6.22103 6.9753 6.2693 6.93456 6.30994L1.68456 11.5599C1.64392 11.6007 1.59564 11.633 1.54248 11.6551C1.48933 11.6771 1.43235 11.6885 1.37481 11.6885C1.31726 11.6885 1.26028 11.6771 1.20713 11.6551C1.15397 11.633 1.1057 11.6007 1.06506 11.5599Z"
              fill="#1F2937" stroke="black" stroke-width="0.5" />
          </svg>
        </button>
    </div>

    <!-- Filters container -->
    <div class="flex justify-between md:justify-end items-center md:mt-2 md:mb-2 w-[100%] md:w-[50%]">
      <!-- Select Cohort -->
      <div class="w-[47.5%] md:w-[35%] rounded-[8px] md:mr-4" style="border-color: rgba(212, 215, 221, 1);">
        <div class="relative">

          <select id="cohortsSelect" ref="cohortsSelect" multiple data-hs-select='{
          "placeholder": "Cohorts",
          "toggleTag": "<button type=\"button\"></button>",
          "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-2 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
          "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
          "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
          "optionTemplate": "<div class=\"flex justify-start items-center w-full min-w-[92px]\"><div class=\"w-[10px] h-[20px]  md:w-[22px] md:h-[18px] mr-2 rounded-[2px] cohort-color-label-class \" ></div> <span class=\"cohort-name-span\" data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
        }'
            class="hidden">
            <option v-if="this.userRoleView && (this.userRoleView == 'System Manager' || this.userRoleView =='Educator' )" v-for="cohort in cohortsOptions" :key="cohort.name" :value="cohort.cohort_name">      
             {{cohort.cohort_name}}
            </option>

            <option v-if="this.userRoleView && this.userRoleView== 'Parent'" v-for="option in optionsResponse.new_cohorts":key="option.id" :value="option.name">
                 {{ option.name }}
            </option>
          </select>
          <div class="absolute top-1/2 end-3 -translate-y-1/2">
            <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
              width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round">
              <path d="m7 15 5 5 5-5" />
              <path d="m7 9 5-5 5 5" />
            </svg>
          </div>
        </div>
        <!-- End Select -->
      </div>

      <!-- Select Facilitator -->
      <div v-if="this.userRoleView && ( this.userRoleView == 'System Manager' || this.userRoleView =='Educator' )" class="w-[47.5%] md:w-[35%] rounded-[8px]" style="border-color: rgba(212, 215, 221, 1);">
        <div class="relative">

          <select id="educatorsSelect" ref="educatorsSelect" multiple data-hs-select='{
          "placeholder": "Facilitator",
          "toggleTag": "<button type=\"button\"></button>",
          "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-2 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
          "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
          "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
          "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
        }' class="hidden">
            <option v-for="educator in educatorsOptions" :key="educator.name" :value="educator.educator_name">{{
        educator.educator_name }}</option>
          </select>
          <div class="absolute top-1/2 end-3 -translate-y-1/2">
            <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
              width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round">
              <path d="m7 15 5 5 5-5" />
              <path d="m7 9 5-5 5 5" />
            </svg>
          </div>
        </div>
        <!-- End Select -->
      </div>

    </div>
  </div>

    <!--Metadata Tooltip Component -->
    <Tooltip :visible="showTooltip1" :position="tooltipPosition" style="z-index: 100">
      <div class="text-xs">
          Morning Activity
       <br>
          Activity Name
      </div>    
    </Tooltip> 

    <!-- Morning Activity logo Tooltip -->
    <Tooltip :visible="showMorningTooltip" :position="tooltipPosition" style="z-index: 100">
        <div class="text-xs">
          Morning Activity
        </div>
    </Tooltip> 

    <!-- Afternoon Activity logo Tooltip -->
    <Tooltip :visible="showAfternoonTooltip" :position="tooltipPosition" style="z-index: 100">
        <div class="text-xs">
          Afternoon Activity
        </div>
    </Tooltip> 

    <!-- Shared Event logo Tooltip -->
    <Tooltip :visible="showSharedEventTooltip" :position="tooltipPosition" style="z-index: 100">
        <div class="text-xs">
          Shared Event
        </div>
    </Tooltip> 
  

  <!-- Table -->
  <div class="flex flex-col h-[100%] md:h-auto">
    <div class="h-[100%]">
      <div class="h-[100%]">
        <div class="fade-container overflow-y-auto md:overflow-auto h-[85%] md:h-[78vh] md:w-[100%] table-container"
        >

          <table class="min-w-full border-gray-300 overflow-y-hidden table-fixed">
            
            <!-- Header/date section of table -->
            <thead>
              <tr>
                <th scope="col"
                  class="sticky top-0 left-0 md:top-[%] z-10 px-6 py-3 text-start text-xs font-medium text-gray-500 bg-white dark:bg-gray-800 border-r border-gray-300 md:min-w-[10rem]"
                  style="background-color:rgba(254, 243, 194, 1);"></th>

                <!-- Conditional rendering of date header row based on viewMode -->

                <!-- Week view  -->
                <template v-if="viewMode === 'weekly'">
                  <th style="background-color:rgba(254, 243, 194, 1);" scope="col" v-for="date in datesOfCurrentWeek"
                    :style="{ backgroundColor: isCurrentDate(date) ? 'rgba(253, 216, 53, 1)' : 'rgba(254, 243, 194, 1)' }"
                    :key="date"
                    :ref="`day-${date}`"
                    :id="`day-${date}`"
                    class="sticky top-0 z-5 md:top-[%] min-w-[105px] left-0 px-5 md:px-10 py-1 md:py-0 lg:py-2.5 text-start text-[13px] font-semibold text-black bg-white dark:bg-gray-800 border-r border-gray-300">
                    {{ date }}
                   
                    <!-- Template to render miscellaneous events -->
                    <template v-if="miscellaneousEvents !== null">
                      <div v-if="getActivityTitle(date)" class="text-[11.5px] mt-1 flex">
                        
                        <span class="text-gray-800 mr-0.5">{{ getActivityTitle(date).title }}</span>
                       
                        <!-- Preline dropdown -->
                        <div class="hs-dropdown relative inline-flex">
                          <button id="hs-dropdown-custom-trigger" type="button" class="hs-dropdown-toggle">
                            <span class="text-blue-500">{{ getActivityTitle(date).additionalEvents }}</span>
                          </button>
                          <div class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-60 bg-white shadow-md rounded-lg p-2 mt-2 dark:bg-gray-800 dark:border dark:border-gray-700" aria-labelledby="hs-dropdown-custom-trigger">
                            <div v-for="(event, index) in getActivityTitle(date).matchingEvents" :key="index" class="text-gray-700 text-sm mt-3">
                              {{ event.activity_title }}
                            </div>
                          </div>
                        </div>
                        <!-- dropdown end -->

                      </div>
                    </template>

                  </th>
                </template>               

                <!-- Month view -->
                <template v-else>
                  <th style="background-color:rgba(254, 243, 194, 1);" scope="col" v-for="date in datesOfMonth"
                    :key="date"
                    class="sticky top-0 px-6 py-3 text-start text-xs font-bold text-gray-500 uppercase bg-white dark:bg-gray-800 border-r border-gray-300 min-w-[rem]">
                    {{ date }}</th>
                </template>
              </tr>
            </thead>

            <!-- Body of the table -->
            <template v-for="cohort in get_cohort_render_order(Object.keys(eventsByCohort))" :key="`cohort-${cohort}`">

              <!-- Invitation to play -->
              <template
                v-if="(cohort === 'All') && (eventsExistInCohort('All')) && (invitationToPlayEventsExistInCohort('All'))">

                
                <!--Activity row -->
                <tr>
                    <td :style="{ 'box-shadow': 'inset -10px 0 0 0 ' + getCohortColor(cohort)}"
                    class="sticky left-0 bg-white dark:bg-gray-800 px-2 py-0 h-[40px] text-sm font-medium text-gray-800 dark:text-gray-200 w-[20px]">
                    {{ 'Invitation to play' }}</td>

                  <template v-for="dateOfWeek in currentDates" :key="`cohort-${cohort}-date-${dateOfWeek}`">

                    <td @click="handleCellClick({
                        cohort: cohort,
                        label: 'Invitation to play',
                        id: eventsByCohort[cohort][dateOfWeek][0].id,
                        title: eventsByCohort[cohort][dateOfWeek][0].title,
                        start: eventsByCohort[cohort][dateOfWeek][0].start
                      })"
                     v-if="inivitationToPlayExist(eventsByCohort, cohort, dateOfWeek)" :style="{borderRight: '1px solid #B9B9B9'}" class="text-sm text-gray-800 md:max-w-[12rem] cursor-pointer"> 
                       
                      <div class="flex items-center space-x-2 max-w-[10rem] md:max-w-[12rem]">
                        <template v-if="inivitationToPlayExist(eventsByCohort, cohort, dateOfWeek)">
                          <img src="@/assets/invitation_to_play_logo.png" alt="Invitation to play" height="25"
                              width="25">
                        </template>  
                        <span>{{'Invitation to Play'}}</span>
                      </div>
                    </td>

                    <td @click="handleEmptyCellClick({
                        cohort: 'All',
                        start: dateOfWeek,
                        session: 'Invitation to Play'
                      })"        
                        v-else :style="{borderRight: '1px solid #B9B9B9', 
                        backgroundColor: isHoliday(dateOfWeek) ? 'rgba(254, 243, 194, 1)' : ''}" class="cursor-pointer text-sm text-gray-800">
                       <div v-if="isHoliday(dateOfWeek)"> Holiday</div>
                    </td>

                  </template>
                </tr>

                <!-- Led By row -->
                <tr>
                  <!-- rendering data coulmns for invitation to play -->
                  <td :style="{ 'box-shadow': 'inset -10px 0 0 0 ' + getCohortColor(cohort), borderBottom: '1px solid #B9B9B9'}"
                    class="sticky left-0 bg-white dark:bg-gray-800 px-2 py-0 h-[40px] text-sm font-medium text-gray-800 dark:text-gray-200 w-[20px]">{{ 'Led by' }}</td>

                  <template v-for="dateOfWeek in currentDates" :key="`cohort-${cohort}-date-${dateOfWeek}`">

                    <td @click="handleCellClick({
                        cohort: cohort,
                        label: 'Invitation to play',
                        id: eventsByCohort[cohort][dateOfWeek][0].id,
                        title: eventsByCohort[cohort][dateOfWeek][0].title,
                        start: eventsByCohort[cohort][dateOfWeek][0].start
                      })"
                    v-if="inivitationToPlayExist(eventsByCohort, cohort, dateOfWeek)"
                    :style="{ borderBottom: '1px solid #B9B9B9', borderRight: '1px solid #B9B9B9' }" class="text-sm text-gray-800 md:max-w-[12rem] cursor-pointer"> {{
                            eventsByCohort[cohort][dateOfWeek][0]['led_by'].sort().join('+') }}
                    </td>

                    <td @click="handleEmptyCellClick({
                        cohort: 'All',
                        start: dateOfWeek,
                        session: 'Invitation to Play'
                      })"  
                    v-else :style="{ borderBottom: '1px solid #B9B9B9', borderRight: '1px solid #B9B9B9',
                        backgroundColor: isHoliday(dateOfWeek) ? 'rgba(254, 243, 194, 1)' : ''
                     }" class="cursor-pointer text-sm text-gray-800">
                    </td>

                  </template>
                </tr>

              </template>

              <!-- Circle Time -->
              <template
                v-if="(cohort === 'All') && (eventsExistInCohort('All')) && (circleTimeEventsExistInCohort('All'))">

                <!-- Activity row -->
                <tr>              
                  <td :style="{ 'box-shadow': 'inset -10px 0 0 0 ' + getCohortColor(cohort)}"
                    class="sticky left-0 bg-white dark:bg-gray-800 px-2 py-0 h-[40px] text-sm font-medium text-gray-800 dark:text-gray-200 w-[20px]">
                    {{ 'Circle Time' }}</td>

                  <template v-for="dateOfWeek in currentDates" :key="`cohort-${cohort}-date-${dateOfWeek}`">

                    <td @click="handleCellClick({
                        cohort: cohort,
                        label: 'Circle Time',
                        id: eventsByCohort[cohort][dateOfWeek][1].id,
                        title: eventsByCohort[cohort][dateOfWeek][1].title,
                        start: eventsByCohort[cohort][dateOfWeek][1].start
                      })"
                    v-if=" circleTimeExist(eventsByCohort, cohort, dateOfWeek)"  :style="{borderRight: '1px solid #B9B9B9'}" class="text-sm text-gray-800 md:max-w-[12rem] cursor-pointer">
                      
                      <div class="flex items-center space-x-2 max-w-[10rem] md:max-w-[12rem]">
                        <template
                            v-if="circleTimeExist(eventsByCohort, cohort, dateOfWeek)">
                            <img src="@/assets/circle_time_logo.png" alt="Circle time" height="25" width="25">
                        </template>

                        <span>{{'Circle Time'}}</span>
                      </div>  
                    </td>

                    <td @click="handleEmptyCellClick({
                        cohort: 'All',
                        start: dateOfWeek,
                        session: 'Circle Time'
                      })"        
                        v-else :style="{borderRight: '1px solid #B9B9B9',  backgroundColor: isHoliday(dateOfWeek) ? 'rgba(254, 243, 194, 1)' : ''}" class="cursor-pointer text-sm text-gray-800">

                    </td>

                  </template>
                </tr>

                <!-- Led by row -->
                <tr>
                  <td :style="{ 'box-shadow': 'inset -10px 0 0 0 ' + getCohortColor(cohort), borderBottom:'1px solid #B9B9B9'}"
                    class="sticky left-0 bg-white dark:bg-gray-800 px-2 py-0 h-[40px] text-sm font-medium text-gray-800 dark:text-gray-200 w-[20px]">
                    {{ 'Led By' }}</td>

                  <template v-for="dateOfWeek in currentDates" :key="`cohort-${cohort}-date-${dateOfWeek}`">

                    <td @click="handleCellClick({
                        cohort: cohort,
                        label: 'Circle Time',
                        id: eventsByCohort[cohort][dateOfWeek][1].id,
                        title: eventsByCohort[cohort][dateOfWeek][1].title,
                        start: eventsByCohort[cohort][dateOfWeek][1].start
                      })"
                       v-if="circleTimeExist(eventsByCohort, cohort, dateOfWeek)" :style="{ borderBottom: '1px solid #B9B9B9', borderRight: '1px solid #B9B9B9' }" class="text-sm text-gray-800 md:max-w-[12rem]"> {{
                           eventsByCohort[cohort][dateOfWeek][1]['led_by'].sort().join('+') }}
                    </td>

                    <td @click="handleEmptyCellClick({
                        cohort: 'All',
                        start: dateOfWeek,
                        session: 'Circle Time'
                      })"  
                    v-else :style="{ borderBottom: '1px solid #B9B9B9',
                     borderRight: '1px solid #B9B9B9', 
                    backgroundColor: isHoliday(dateOfWeek) ? 'rgba(254, 243, 194, 1)' : '' }" class="cursor-pointer text-sm text-gray-800">
                    </td>      

                  </template>
                </tr>

              </template>

              <!-- for morning session -->
              <!-- Activity row -- Competency row ---- Led By row -->
              <template v-for="row in rowTypes" :key="`row-${cohort}-${row.label}`">


                <tr v-if="eventsExistInCohort(cohort) && (cohort !== 'All') && morningEventExistInCohort(cohort)">
                 
                  <!-- Meta data column -->
                  <td
                    :style="{ 'z-index': '5', 'box-shadow': 'inset -10px 0 0 0 ' + getCohortColor(cohort), borderBottom: row.label == 'Led By' ? '1px solid #B9B9B9' : '0px solid #B9B9B9' }"
                    class="sticky-element sticky left-0 dark:bg-gray-800 px-3 py-0 h-[40px] text-sm font-medium text-gray-800 dark:text-gray-200 w-[20px] bg-white ">


                    <!-- Element that triggers the tooltip -->
                    <div @mouseenter="showTooltip1 = true; updateTooltipPosition($event)" @mouseleave="showTooltip1 = false">
                   
                      {{ checkMetaData(cohort, row.label) }}
                    </div>                                                  

                  </td>
                  <!-- Nested loop for each date of the week -->
                  <template v-for="dateOfWeek in currentDates" :key="`cohort-${cohort}-date-${dateOfWeek}`">
                    <!-- v-if for filled cells -->
                    <td @click="handleCellClick({
                        cohort: cohort,
                        label: row.label,
                        id: eventsByCohort[cohort][dateOfWeek][0].id,
                        title: eventsByCohort[cohort][dateOfWeek][0].title,
                        start: eventsByCohort[cohort][dateOfWeek][0].start
                      })" :style="{ borderBottom: row.label == 'Led By' ? '1px solid #B9B9B9' : '0px solid #B9B9B9', borderRight: '1px solid #B9B9B9' }"
                      v-if="Object.keys(eventsByCohort[cohort]).includes(dateOfWeek)&& (eventsByCohort[cohort][dateOfWeek].length>=1) && Object.keys(eventsByCohort[cohort][dateOfWeek][0]).length>=1" 
                      class="text-sm text-gray-800 cursor-pointer">
                      <!-- Dynamically access the property based on row.key -->
                
                      <div class="flex items-center space-x-2 max-w-[10rem] md:max-w-[12rem] md:pl-2 md:pr-1"
                        :style="{ 'justify-content': row.key === 'led_by' ? 'space-between' : 'default' }">
                        <template
                          v-if="eventsByCohort[cohort][dateOfWeek][0].session === 'Morning' && row.key === 'title'">

                          <div @mouseenter="showMorningTooltip = true; updateTooltipPosition($event)" @mouseleave="showMorningTooltip = false"> 
                            <img src="@/assets/morning_logo.png" alt="Morning logo" height="20" width="20" style="max-width: none;">
                          </div> 
                                 
                        </template>

                        <span class="truncate">{{ checkMorningRowData(row.key, eventsByCohort,cohort,dateOfWeek)  }}</span>
                      
                       
                       <!--Shrared events logo wrapped in custom tooltip  -->
                       <template v-if="eventsByCohort[cohort][dateOfWeek][0].isGroupEvent && row.key === 'led_by'">                      
                          <div @mouseenter="showSharedEventTooltip = true; updateTooltipPosition($event)" @mouseleave="showSharedEventTooltip = false">     
                            <img src="@/assets/shared_event.png" alt="Shared Event" height="25" width="21" style="max-width: none;">
                          </div> 
                        </template>
                       
                        <template
                        v-if="(eventsByCohort[cohort][dateOfWeek][0].title === undefined || eventsByCohort[cohort][dateOfWeek][0].led_by.length === 0 || eventsByCohort[cohort][dateOfWeek][0].competency[0] === null) && eventsByCohort[cohort][dateOfWeek][0].session !== 'Invitation to Play' && row.key === 'led_by'">
                          
                        <!--Preline Tool-tip -->
                        <div class="col-start-3">
                          <div class="hs-tooltip inline-block [--placement:right]">
                            <button type="button" class="hs-tooltip-toggle">

                              <img src="@/assets/empty_indicator_logo.png" alt="Empty indicator" height="30" width="30" style="max-width: none;">
                      
                              <div
                                class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity absolute invisible z-50 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-slate-700"
                                role="tooltip">
                                Incomplete Indicator
                              </div>
                            </button>
                          </div>
                        </div>
                        <!-- End Tool-tip -->
                        
                        </template>

                        
                      </div>
                    </td>

                    <!-- v-else for empty cells-->
                    <td @click="handleEmptyCellClick({
                        cohort: this.allCohortsList.find(c => c.cohort_name === cohort),
                        start: dateOfWeek,
                        session: 'Morning'
                      })"
                    class=""    
                    :style="{ borderBottom: row.label == 'Led By' ? '1px solid #B9B9B9' : '0px solid #B9B9B9',
                     borderRight: '1px solid #B9B9B9',
                     backgroundColor: isHoliday(dateOfWeek) ? 'rgba(254, 243, 194, 1)' : '' 
                     }"
                      v-else>
                      
                    </td>
                  </template>
                </tr>
              </template>


              <!-- for afternoon session -->
              <!-- Activity row -- Competency row ---- Led By row -->

              <template v-for="row in afternoonRowTypes" :key="`afternoon-row-${cohort}-${row.label}`">

                <tr v-if="eventsExistInCohort(cohort) && (cohort !== 'All') && afternoonEventExistInCohort(cohort)">
                  <td
                    :style="{ 'box-shadow': 'inset -10px 0 0 0 ' + getCohortColor(cohort), borderBottom: row.label == 'Led By' ? '1px solid #B9B9B9' : '0px solid #B9B9B9' }"
                    class="sticky-element sticky left-0 bg-white dark:bg-gray-800 px-2 py-0 h-[40px] text-sm font-medium text-gray-800 dark:text-gray-200 w-[20px]">

                    <div @mouseenter="showTooltip1 = true; updateTooltipPosition($event)" @mouseleave="showTooltip1 = false">
     
                      {{ cohort === 'All' && row.label === 'Afternoon Activity' ? 'Circle Time' :
                            row.label === 'Led By' ? 'Led By (Room)' :
                            row.label === 'Afternoon Competency' ? 'Competency':
                            row.label === 'Afternoon Activity' ?`${cohort}` : '' }}
                    </div>         

                  </td>

                  <template v-for="dateOfWeek in currentDates" :key="`afternoon-cohort-${cohort}-date-${dateOfWeek}`">
                    <td @click="handleCellClick({
                          cohort: cohort,
                          label: row.label,
                          id: eventsByCohort[cohort][dateOfWeek][1].id,
                          title: eventsByCohort[cohort][dateOfWeek][1].title,
                          start: eventsByCohort[cohort][dateOfWeek][1].start
                        })" :style="{ borderBottom: row.label == 'Led By' ? '1px solid #B9B9B9' : '0px solid #B9B9B9', borderRight: '1px solid #B9B9B9' }"
                      v-if="Object.keys(eventsByCohort[cohort]).includes(dateOfWeek) && (eventsByCohort[cohort][dateOfWeek].length >= 2) && Object.keys(eventsByCohort[cohort][dateOfWeek][1]).length>=1" 
                      class="text-sm text-gray-800 cursor-pointer">

                      <div class="flex items-center space-x-2 max-w-[10rem] md:max-w-[12rem]"
                        :style="{ 'justify-content': row.key === 'led_by' ? 'space-between' : 'default' }">
                        
                        <template
                          v-if="eventsByCohort[cohort][dateOfWeek][1].session === 'Afternoon' && row.key === 'title'">

                          <div @mouseenter="showAfternoonTooltip = true; updateTooltipPosition($event)" @mouseleave="showAfternoonTooltip = false"> 
                            <img src="@/assets/afternoon_logo.png" alt="Afternoon logo" height="20" width="20" style="max-width: none;">
                          </div>    
                        </template>

                        <span class="truncate">{{ checkAfternoonRowData(row.key, eventsByCohort,cohort,dateOfWeek)}}</span>

                        <!-- Shared events logo -->
                        <template v-if="eventsByCohort[cohort][dateOfWeek][1].isGroupEvent && row.key === 'led_by'">
                          <div @mouseenter="showSharedEventTooltip = true; updateTooltipPosition($event)" @mouseleave="showSharedEventTooltip = false">        
                             <img src="@/assets/shared_event.png" alt="Shared Event" height="25" width="21" style="max-width: none;">
                          </div>      
                        </template>
                        
                        <template
                          v-if="(eventsByCohort[cohort][dateOfWeek][1].title === undefined || eventsByCohort[cohort][dateOfWeek][1].led_by.length === 0 || eventsByCohort[cohort][dateOfWeek][1].competency[0] === null) && eventsByCohort[cohort][dateOfWeek][1].session !== 'Circle Time' && row.key === 'led_by'">

                          <!--Preline Tool-tip -->
                          <div class="col-start-3">
                            <div class="hs-tooltip inline-block [--placement:right]">
                              <button type="button" class="hs-tooltip-toggle">

                                <img src="@/assets/empty_indicator_logo.png" alt="Empty indicator" height="30" width="30" style="max-width: none;">
                     
                                <div
                                  class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity absolute invisible z-50 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-slate-700"
                                  role="tooltip">
                                  Incomplete Indicator
                                </div>
                              </button>
                            </div>
                          </div>
                          <!-- End Tool-tip -->
                        </template>

                      </div>

                    </td>
                    
                    <!-- V-else when cell is empty-->
                    <td @click="handleEmptyCellClick({
                        cohort: this.allCohortsList.find(c => c.cohort_name === cohort),
                        start: dateOfWeek,
                        session: 'Afternoon'
                      })"      
                      class=""  
                      :style="{ borderBottom: row.label == 'Led By' ? '1px solid #B9B9B9' : '0px solid #B9B9B9',
                       borderRight: '1px solid #B9B9B9',
                       backgroundColor: isHoliday(dateOfWeek) ? 'rgba(254, 243, 194, 1)' : '' }"
                      v-else>

                    </td>
                  </template>


                </tr>
              </template>


            </template>

          </table>
          
        </div>
      </div>
    </div>
  </div>

</template>


<script>
import { onMounted, nextTick } from 'vue';
import ToolTip from './ToolTip.vue';
import moment from 'moment';



export default {
  inject: ["$call"],
  components: {
     Tooltip: ToolTip
   },
  props: {
    events: {
      type: Array,
      default: () => [] // Provide a default empty array if events prop is not passed
    },
    optionsResponse: {
      type: Object,
      required: true,
    },
    allCohortsList: {
      type: Array,
      default: () => []
    },
    userRoles: { // Define the userRoles prop
      type: Array,
      default: () => [],
    },
    filtersApplied: {
      type: Object,
      required: false
    },
    parentsChildInfo:{
      type: Array,
      required:false
    },
    userRoleView: {
      type: String,
      required: true,
    }
  },

  data() {
    return {
      viewMode: 'weekly',
      currentWeekStart: moment().startOf('isoWeek'),
      currentSessionFlag: null, // This will hold 'morning' or 'afternoon'

      cohortsOptions: [],
      educatorsOptions: [],
      headerMonthYear: moment().format("MMMM YYYY"),

      rowTypes: [
        { label: 'Morning Activity', key: 'title' },
        { label: 'Morning Competency', key: 'competency' },
        { label: 'Led By', key: 'led_by' },
      ],
      afternoonRowTypes: [
        { label: 'Afternoon Activity', key: 'title' },
        { label: 'Afternoon Competency', key: 'competency' },
        { label: 'Led By', key: 'led_by' },
      ],

      currentFilter : {},
      miscellaneousEvents: null,

      showTooltip1: false,
      showMorningTooltip: false,
      showAfternoonTooltip:false,
      showSharedEventTooltip:false,
      tooltipPosition: { x: 0, y: 0 }
    };
  },
  
  mounted() {
    this.assignOptionsData();
    setTimeout(() => {
      window.HSStaticMethods.autoInit();
      console.log("Preline initialised in TableView.vue");

      // Initialize the select element for cohorts
      const selectElement = window.HSSelect.getInstance('#cohortsSelect');
      if (selectElement) {
        selectElement.on('change', (value) => {
          console.log('Select value changed:', value);
          // Emits event with the new value
          this.$emit('cohorts-select-changed', { type: 'cohorts', value: value });

        });
      } else {
        console.log('AdvancedSelect instance not found');
      }

      // Initialize the select element for educators
      const educatorsSelectElement = window.HSSelect.getInstance('#educatorsSelect');
      if (educatorsSelectElement) {
        educatorsSelectElement.on('change', (value) => {
          console.log('Educator select value changed:', value);
          // Emits event with the new value for educators
          this.$emit('educators-select-changed', { type: 'educators', value: value });

        });
      } else {
        console.log('Educators AdvancedSelect instance not found');
      }


      // Call assignOptionsData here to ensure it runs after the above code
      console.log('OptionsResponse prop in table view:', this.optionsResponse);
      this.setCohortColors();

    }, 100);

    this.scrollToDay();
    this.fetchMiscellaneousEvents()

  },
  watch: {
    miscellaneousEvents: {
      immediate: true,
      handler(newVal, oldVal) {
       
        if (newVal !== null) {
          nextTick(() => {           
            setTimeout(() => {
              window.HSStaticMethods.autoInit();
             
            }, 100); // Add a small delay if necessary
          });
        } else {
          console.log('newVal of miscellaneous events is null, not Reinitializing Preline');
        }
      },
    },
  },
  computed: {

    currentDates() {
      // Return 'datesOfMonth' if viewMode is 'monthly', otherwise 'datesOfCurrentWeek'
      return this.viewMode === 'monthly' ? this.datesOfMonth : this.datesOfCurrentWeek;
    },
    datesOfMonth() {
      const year = new Date().getFullYear();
      const month = new Date().getMonth();
      const daysInMonth = new Date(year, month + 1, 0).getDate();

      let dates = [];
      for (let day = 1; day <= daysInMonth; day++) {
        const date = new Date(year, month, day);
        // Format parts of the date separately
        const dayString = date.toLocaleDateString('default', { day: '2-digit' }); // '01'
        const monthString = date.toLocaleDateString('default', { month: 'short' }); // 'Jan'
        const weekdayString = date.toLocaleDateString('default', { weekday: 'short' }); // 'Mon'

        // Concatenate the parts in the desired order
        const dateString = `${dayString} ${monthString} ${weekdayString}`; // '01 Jan Mon'
        dates.push(dateString);
      }
      return dates;
    },
    datesOfCurrentWeek() {
      let week = [];
      for (let i = 0; i < 5; i++) {  // Loop from Monday to Friday
        week.push(this.currentWeekStart.clone().add(i, 'days').format("DD MMM ddd"));  // "01 Feb Mon" format
      }
      return week;
    },
   
    // Assuming this.allCohortsList is an array of cohort objects
    eventsByCohort() {
     
      // check if parent cohorts are available and create a flag to use
      const parentCohorts = new Set(this.parentsChildInfo?.map(cohort => cohort.name) || []);
      const useParentCohorts = parentCohorts.size > 0;

      let grouped = {};

      // this.allCohortsList.forEach(cohort => {

      //   const cohortKey = cohort.cohort_name; // Adjust based on your data structure
      //   grouped[cohortKey] = {};
      //   console.log("This is grouped cohort key---",{...grouped[cohortKey]})

      // });
      console.log("This is grouped object json before temp event---", JSON.stringify(grouped, null, 2));

      let tempEvents = [];

      // Process each event to determine its priority and add it to tempEvents
      this.events.forEach(event => {
        
        if (event.session === "Invitation to Play" && event.cohorts.length > 1) {
          // Highest priority: "Invitation to Play" with combined cohorts
          tempEvents.unshift({ event, priority: 1 });  // Prepend to ensure highest priority
        } else if (event.session === "Circle Time" && event.cohorts.length > 1) {
          // Second highest priority: "Circle Time" with combined cohorts
          const firstNonInvitationIndex = tempEvents.findIndex(e => e.priority !== 1);
          tempEvents.splice(firstNonInvitationIndex >= 0 ? firstNonInvitationIndex : tempEvents.length, 0, { event, priority: 2 });
        } else {
          // Other events are added without specific priority
          tempEvents.push({ event, priority: 3 });  // Append to ensure lower priority
        }
      });
      // console.log("This is grouped after temp event json ---", JSON.stringify(grouped, null, 2));

      // Now, populate the grouped object using the ordered tempEvents
      tempEvents.forEach(({ event }) => {

        const relevantCohorts = useParentCohorts ? event.cohorts.filter(cohort => parentCohorts.has(cohort)): event.cohorts;

        const eventDate = moment(event.start, "YYYY-MM-DD").format("DD MMM ddd");

        if (event.cohorts.length > 1 && event.title !== 'Invitation to Play' && event.title !== 'Circle Time') {

          //  Adding a new isGroupevet field here
          event.isGroupEvent = true;
          
          console.log("these are relevant cohorts ============>",relevantCohorts)
          // Iterate over each cohort in the event
          relevantCohorts.forEach(cohortName => {
            // Ensure there's an entry for this cohort in 'grouped'
            if (!grouped[cohortName]) {
              grouped[cohortName] = {};
            }

            // Ensure there's an entry for this date within the cohort
            if (!grouped[cohortName][eventDate]) {
              grouped[cohortName][eventDate] = [];
            }

            // Add the event to this cohort's date array
            grouped[cohortName][eventDate].push(event);
            // console.log("This is grouped after new If block ---", JSON.stringify(grouped, null, 2));
          });

        } else {

          const key = event.cohorts[0]; // Single cohort name as key

          if (!grouped[key]) {
            grouped[key] = {};
          }

          if (!grouped[key][eventDate]) {
            grouped[key][eventDate] = [];
          }
          if ((event.session === 'Invitation to Play') || (event.session === 'Circle Time')) {

            if (!grouped['All']) {
              grouped['All'] = {}
            }

            if (!grouped['All'][eventDate]) {
              grouped['All'][eventDate] = []
            }

            grouped['All'][eventDate].push(event)
          } else {
            grouped[key][eventDate].push(event);
          }
        }
        Object.keys(grouped).forEach(key => {
          if (key === 'undefined' || key === 'null') {
            delete grouped[key];
          }
        });
      });

      console.log("These are grouped events--", { ...grouped })
      // Sort the events within each cohort and date in grouped by session priority
      Object.keys(grouped).forEach(cohortKey => {
        Object.keys(grouped[cohortKey]).forEach(dateKey => {
          grouped[cohortKey][dateKey].sort((a, b) => {
            // Define the order of session values
            const order = ["Invitation to Play", "Circle Time", "Morning", "Afternoon"];
            // Compare the indices of session values in the order array
            return order.indexOf(a.session) - order.indexOf(b.session);

          });
          // Check if the sorted events for the date include "Invitation to Play" or "Morning" session
          const hasImportantSession = grouped[cohortKey][dateKey].some(event => event.session === "Invitation to Play" || event.session === "Morning");

          // If not, prepend an empty object as a placeholder
          if (!hasImportantSession) {
            grouped[cohortKey][dateKey].unshift({});
          }

        });
      });
      console.log("These are Sorted grouped events--", grouped)

      // sending only the selected cohorts to remove other groupe cohorts 

      
      if(('cohorts' in this.filtersApplied) && this.filtersApplied['cohorts'].length>0){
        var eventsToDisplay = {}
        for (let index = 0; index < this.filtersApplied['cohorts'].length; index++) {
          const cohort = this.filtersApplied['cohorts'][index];
          if(cohort in grouped){
            eventsToDisplay[cohort] = grouped[cohort];
          }         
        }

        grouped = eventsToDisplay;
        console.log('applying filter based on groups')
      }

      return grouped;
    },
    


  },
  methods: {

    isHoliday(date) {
      return !Object.keys(this.eventsByCohort).some(cohort => {
        return this.eventsByCohort[cohort][date] && this.eventsByCohort[cohort][date].length > 0;
      });
    },
    
    updateTooltipPosition(event) {
      const boundingRect = event.target.getBoundingClientRect();
  this.tooltipPosition = {
    x: boundingRect.right + window.scrollX -80, // Position to the right of the element
    y: boundingRect.top + window.scrollY +10// Align with the top of the element
      }
    },
    inivitationToPlayExist(eventsByCohort, cohort, date) {
      return eventsByCohort[cohort] && eventsByCohort[cohort][date] && eventsByCohort[cohort][date].length>=1 && eventsByCohort[cohort][date][0]['session']
    },
    circleTimeExist(eventsByCohort, cohort, date) {
      return eventsByCohort[cohort] && eventsByCohort[cohort][date] && eventsByCohort[cohort][date].length>1  && eventsByCohort[cohort][date][1]['session']
    },

    checkMetaData(cohort, label) {
      if (cohort === 'All' && label === 'Morning Activity') {
        return 'Invitation to play'
      }

      else if (label === 'Led By') {
        return 'Led By (Room)'
      }
      else if (cohort !== 'All' && label == 'Morning Activity') {
        return cohort
      }
      else if (cohort !== 'All' && label === 'Morning Competency') {
        return 'Competency'
      }
      else {
        return ''
      }
    },
    checkMorningRowData(row, eventsByCohort,cohort,dateOfWeek){
      if (row === 'led_by' && eventsByCohort[cohort][dateOfWeek][0].location){
        
        const rowData = eventsByCohort[cohort][dateOfWeek][0][row];
        const locationData = eventsByCohort[cohort][dateOfWeek][0].location;
        
        // Combine rowData and locationData
        let combinedData = rowData + ' (' + locationData + ')';
        
        // Check if the combined length exceeds 15 characters
        if (combinedData.length >=15) {
          // Process rowData to keep only the first 3 words of each name
          const processedRowData = rowData.map(name => { return name.slice(0, 3)  }).join('+');         

          combinedData = processedRowData + ' (' + locationData + ')';    
        }
      return combinedData;
                
      }
      else if(row === 'title'){
        if (eventsByCohort[cohort][dateOfWeek][0][row]){
          return eventsByCohort[cohort][dateOfWeek][0][row]
        }
        else if (eventsByCohort[cohort][dateOfWeek][0]["activity_name"]) {
          return eventsByCohort[cohort][dateOfWeek][0]["activity_name"]
        } 
      }
      else {
        const rowData =  eventsByCohort[cohort][dateOfWeek][0][row] 
        if (Array.isArray(rowData)) {
            return rowData.sort().join('+');
          } else {
            // If rowData is a string or a number, just return it
            return rowData;
          } 
      }
    },
    checkAfternoonRowData(row, eventsByCohort,cohort,dateOfWeek){
      if (row === 'led_by' && eventsByCohort[cohort][dateOfWeek][1].location){
        
        const rowData = eventsByCohort[cohort][dateOfWeek][1][row];
        const locationData = eventsByCohort[cohort][dateOfWeek][1].location;
        // Combine rowData and locationData
        let combinedData = rowData + ' (' + locationData + ')';
        const rowDataString = rowData.join('');
        
        // Check if the combined length exceeds 15 characters
        if (combinedData.length >=15) {
          console.log("check afternoon row data called")
          // Process rowData to keep only the first 3 words of each name
          const processedRowData = rowData.map(name => { return name.slice(0, 3)  }).join('+');         

          combinedData = processedRowData + ' (' + locationData + ')';    
        }
      return combinedData;
        
      }
      else if(row === 'title'){
        if (eventsByCohort[cohort][dateOfWeek][1][row]){
          return eventsByCohort[cohort][dateOfWeek][1][row]
        }
        else if (eventsByCohort[cohort][dateOfWeek][1]["activity_name"]) {
          return eventsByCohort[cohort][dateOfWeek][1]["activity_name"]
        } 
      }
      else {
        const rowData =  eventsByCohort[cohort][dateOfWeek][1][row] 
        if (Array.isArray(rowData)) {
            return rowData.join('+');
          } else {
            // If rowData is a string or a number, just return it
            return rowData;
          } 
      }
    },
    
    eventsExistInCohort(cohort) {
      // console.log("is event exist called for --", cohort)
      return this.currentDates.some(dateOfWeek => {
        // Check if the dateOfWeek exists as a key in this.eventsByCohort for the given cohort
        return Object.keys(this.eventsByCohort[cohort]).includes(dateOfWeek);
      });
    },
    morningEventExistInCohort(cohort) {
       
      const result = this.currentDates.some(dateOfWeek => {
        return (this.eventsByCohort[cohort]) && (this.eventsByCohort[cohort][dateOfWeek]) && (this.eventsByCohort[cohort][dateOfWeek].length>=1) && (this.eventsByCohort[cohort][dateOfWeek][0]) 
       
      });
      return result;
    },
    afternoonEventExistInCohort(cohort) {
      
      const result = this.currentDates.some(dateOfWeek => {
        return (this.eventsByCohort[cohort]) && (this.eventsByCohort[cohort][dateOfWeek]) && (this.eventsByCohort[cohort][dateOfWeek].length>1) && (this.eventsByCohort[cohort][dateOfWeek][1])
       
      });
      return result;
    },
    invitationToPlayEventsExistInCohort(cohort) {
     
      return this.currentDates.some(dateOfWeek => {
        if (this.eventsByCohort[cohort][dateOfWeek]) {
          var events = this.eventsByCohort[cohort][dateOfWeek];
          return 'session' in events[0] && events[0].session === 'Invitation to Play'
        } else {

          return false;
        }

      });
    },

    circleTimeEventsExistInCohort(cohort) {
      
      return this.currentDates.some(dateOfWeek => {
        if (this.eventsByCohort[cohort][dateOfWeek]) {
          var events = this.eventsByCohort[cohort][dateOfWeek];
          return (events.length>1) && ('session' in events[1]) && (events[1].session === 'Circle Time')
        } else {
          return false;
        }

      });
    },
    
 scrollToDay() {
    
    const specificDate = moment().format('DD MMM ddd');
    const dayElement = document.getElementById(`day-${specificDate}`);

    if (dayElement) {
      const offset = 113; // Adjust this value based on your layout's requirements
      const leftPosition = Math.max(dayElement.offsetLeft - offset, 0); 
    
      const container = document.querySelector('.table-container');

      if (container) {
        container.scrollTo({
          left: leftPosition,
          behavior: 'smooth'
        });
      } else {
        console.log("Error: Container not found.");
      }
    } else {
      console.log("Error: Day element not found for date", specificDate);
    }
  },

    get_cohort_render_order(cohort_list) {
      
      let cohort_order = [];

      if (cohort_list.indexOf('All') > -1) {
        cohort_list.splice(cohort_list.indexOf('All'), 1)
        cohort_order.push('All')
      }
      // Custom order of cohort names
        const customOrder = ['Bombils','Batatas', 'Batatas-1', 'Batatas-2', 'Bimblis', 'BigKids-1', 'BigKids-2'];

      // Sort the cohort_list based on the custom order
      cohort_list.sort((a, b) => {
        const indexA = customOrder.indexOf(a);
        const indexB = customOrder.indexOf(b);
        return indexA - indexB;
      });

      return [...cohort_order, ...cohort_list];
    },
    getCategoryValue(event, category) {
      // Assuming each event is an object with properties matching category names
      // You might need to adjust the logic depending on your actual data structure
      if (event[category]) {
        return event[category];
      } else {
        return 'NA'; // Return 'NA' if the event doesn't have a value for the category
      }
    },

    getCohortColor(cohortName) {
      const colorMapping = {
        'Jr Batatas': 'rgba(167, 238, 142, 1)',
        'BigKids-1': 'rgba(167, 238, 142, 1)',
        'BigKids-2': 'rgba(255, 138, 0, 1)',
        'Bimblis': 'rgba(234, 148, 255, 1)',
        'Batatas': 'rgba(73, 201, 229, 1)',
        'Bombils': 'rgba(147, 148, 255, 1)',
        'Batatas-1': 'rgba(73, 201, 229, 1)',
        'Batatas-2': 'rgba(73, 201, 229, 1)',
        // Add more mappings as needed
      };

      // Return the color for the given cohort name, or a default color if not found
      return colorMapping[cohortName] || 'rgba(255, 138, 0, 1)'; // Default color
    },

    setCohortColors() {
      console.log("tt:set cohort color for labels called")

      const spans = document.getElementsByClassName('cohort-name-span');
      
      // Get all elements with the class 'cohort-color-label-class'
      const elements = document.getElementsByClassName('cohort-color-label-class');

      // Loop through the elements and set their background color using the cohort name from span.innerText for comparrision
        // Ensure spans and elements are in the same order and iterate through them
      Array.from(spans).forEach((span, index) => {
        const cohortName = span.innerText.trim(); // Get the cohort name from the span
        const backgroundColor = this.getCohortColor(cohortName); // Get the corresponding color
        const element = elements[index]; // Match the color label element by index

        if (element) {
          element.style.backgroundColor = backgroundColor; // Set the background color
          console.log(`Set color for ${cohortName}: ${backgroundColor}`);
        } else {
          console.warn(`No matching element found for cohort: ${cohortName}`);
        }
      });
    },

    toggleViewMode(mode) {
      this.viewMode = mode;
    },

    goToPreviousWeek() {
      console.log("this is data before going to previous week", this.events.length)
      const previousWeekStart = this.currentWeekStart.clone().subtract(1, 'week');
      // Check if the month has changed by comparing the month of the new week start and the current week start
      if (previousWeekStart.month() !== this.currentWeekStart.month()) {
        // Emit an event with the start date of the new week, not necessarily the start of the month

        this.$emit('month-changed', previousWeekStart.format("YYYY-MM-DD"));
        this.headerMonthYear = previousWeekStart.format("MMMM YYYY") // To update the headerbar date
      }
      // Update the current week start to the previous week, maintaining weekly navigation
      this.currentWeekStart = previousWeekStart;
      console.log("this is data after going to previous week", this.events.length)
    },

    goToNextWeek() {
      const nextWeekStart = this.currentWeekStart.clone().add(1, 'week');
      // Check if the month has changed by comparing the month of the new week start and the current week start
      if (nextWeekStart.month() !== this.currentWeekStart.month()) {
        // Emit an event with the start date of the new week, not necessarily the start of the month
        this.$emit('month-changed', nextWeekStart.format("YYYY-MM-DD"));
        this.headerMonthYear = nextWeekStart.format("MMMM YYYY")
      }
      // Update the current week start to the next week, maintaining weekly navigation
      this.currentWeekStart = nextWeekStart;
    },

    updateSessionFlag(sessionValue) {
      this.currentSessionFlag = sessionValue.includes('Morning') ? 'morning' : 'afternoon';
      return true; // Return true to ensure the div is always rendered
    },
    handleCellClick(eventData) {
      console.log('Cell data:', eventData);
      this.$emit('event-clicked', {
        id: eventData.id,
        title: eventData.title,
        start: eventData.date, // Make sure start is a Date object or formatted string

      });
      // Further processing based on eventData
    },
    handleEmptyCellClick(eventData){
      console.log('Cell data:', eventData);
      
      const headerMonthYear = this.headerMonthYear; // e.g., "March 2024"
      const [monthName, year] = headerMonthYear.split(' ');
     
      // Combine the day from eventData.date with the month and year from headerMonthYear
      // Since eventData.date does not contain year, use the year from headerMonthYear
      const fullDateStr = `${eventData.start.split(' ')[0]} ${monthName} ${year}`; // e.g., "12 March 2024"
      
      const formattedDate = moment(fullDateStr, "DD MMMM YYYY").format("YYYY-MM-DD");
      
      this.$emit('empty-event-clicked', {
        cohort: eventData.cohort,
        start: formattedDate, 
        session: eventData.session
      });
      this.$emit('showNewEventForm');
    },
    async assignOptionsData() {
      try {
        
        
        // Directly assign the response values without filtering or sorting
        this.cohortsOptions = this.optionsResponse.cohorts;
        this.educatorsOptions = this.optionsResponse.educators;
      } 
      catch (error) {
        console.error('Error assigning data:', error);
      }
       
    },
    emitShowNewEventForm() {
      this.$emit('showNewEventForm'); // Emit an event named 'showNewEventForm'
    },

    formatValue(value) {
      // Check if the value is an array
      if (Array.isArray(value)) {
        // Join the array elements with a '+' sign
        return value.join(' + ');
      }
      // If not an array, return the value as is
      return value;
    },
    
    // Funtion to highlight today's header
    isCurrentDate(date) {
      const today = new Date();
      const [day, month] = date.split(' ');
      const targetDate = new Date(today.getFullYear(), this.getMonthIndex(month), parseInt(day));
      
      return today.toDateString() === targetDate.toDateString();
    },
    getMonthIndex(month) {
      const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];
      return months.indexOf(month);
    },
    // Funtion to fetch weekly miscellaneous events
    async fetchMiscellaneousEvents() {
    try {
      console.log("fetchMiscellaneousEvents called++++++++++++++++++++++++++++++++++++++++++++++++++++++")
      const today = moment();
      const startDate = today.startOf('week').format("YYYY-MM-DD");
      const endDate = today.endOf('week').format("YYYY-MM-DD");
      
      console.log("Fetching data between", startDate, "and", endDate);
      
      const response = await this.$call('curriculum_planner.curriculum_planner.doctype.curriculum_events.curriculum_events.get_miscellaneous_curriculum_events', {
        start_date: startDate,
        end_date: endDate,
      });

      console.log('Miscellaneous Events:', response);
      this.miscellaneousEvents = response
      // You can assign the response to a component data property or perform any other necessary operations
      
    } catch (error) {
      console.error('Error fetching miscellaneous events:', error);
    }
  },
  getActivityTitle(date) {
    const [day, month, dayOfWeek] = date.split(' ');
    const targetDate = moment(`${day} ${month}`, 'DD MMM').format('YYYY-MM-DD');
  
    const matchingEvents = this.miscellaneousEvents.enriched_events.filter(event => {
      const eventDate = moment(event.start).format('YYYY-MM-DD');
      return eventDate === targetDate;
    });
    
    if (matchingEvents.length === 1) {
      return { title: matchingEvents[0].activity_title, additionalEvents: '', matchingEvents };
    } else if (matchingEvents.length > 1) {
      const firstEvent = matchingEvents[0].activity_title;
      const additionalEvents = matchingEvents.length - 1;
      return { title: firstEvent, additionalEvents: ` +${additionalEvents} more`, matchingEvents };
    } else {
      return { title: '', additionalEvents: '', matchingEvents: [] };
    }
  },

  },

};
</script>

<style>
/* .sticky-element::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 8px 5px 8px 0px rgba(0, 0, 0, 0.2); 
  z-index: -1;
} */


/* .fade-container::after{
  content: '';
  position: absolute;
  top: 25%;
  right: 0;
  bottom: 0;
  width: 70px; /* Width of the fade effect */
  /* background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  z-index: 2;
} */ 

</style>

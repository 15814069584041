<template>
  <div class="rounded bg-[#FFF9E5] whitespace-pre-line text-sm p-3 flex-auto">
    <!-- <p class="font-medium">Summary & suggestions:</p> -->
    <!-- <div class="text-wrap">
      {{ weeklyDataResource.data?.summaries?.overview?.summary || "Summary not found." }}
      {{ props.overview }}
    </div> -->
    <div v-html="parsedSummary" class="hyperlink-observ-div" @click="handleClick"></div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import { computed } from 'vue';

const props = defineProps(['overview', 'cohort'])

const route = useRoute();
const router = useRouter()

const printOnClick = () => {
  console.log("HEllo print on click")
}

const parsedSummary = computed(() => {
  if (!props.cohort) return 'Summary not found.';
  if (!props.overview[props.cohort]) return 'Summary not found.';
  if (props.overview[props.cohort].summary_type !== 'Overview') return 'Summary of wrong type!';
  // return props.overview.summary.replace(/\|\|(.+?)\|\|\[(\w+)\]/g, (match, text, id) => {
  //   return `<span class="observation-span font-medium italic" data-observation="${id}">${text}</span>`;
  // });
  return props.overview[props.cohort].summary.replace(/\|\|(.+?)\|\|\[(\w+)[,;](\w+)\]/g, (match, text, id, type) => {
    return `<span class="observation-span">${text}</span>
    <button class="observation-button py-1 px-2 inline-flex items-center gap-x-2 text-sm font-medium
              rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
              disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700
              dark:text-white dark:hover:bg-neutral-800" data-observation="${id}" data-observation-type="${type}" @click="printOnClick">Observation</button>`;
  });
})

const handleClick = (event) => {
  // Check if the clicked element is an observation span
  const target = event.target;
  if (target.classList.contains("observation-button")) {
    const observationId = target.getAttribute("data-observation");
    const observationType = target.getAttribute("data-observation-type");
    showObservation(observationId, observationType);
  }
}

const showObservation = async (observationId, observationType='activity') => {
  console.log("Observation ID:", observationId);
  console.log("Observation Type:", observationType);
  router.push({
    path: route.path,
    query: {
      ...Object.fromEntries(Object.entries(route.query).filter(([key]) => !['observType', 'observVersion', 'observName'].includes(key)))
    }
  })
  await new Promise((resolve) => setTimeout(resolve, 0))
  router.push({
            path: route.path,
            query: {
              ...route.query,
              // observType: getObservType(props.notification.source_type),
              observType: observationType,
              observName: observationId,
              observVersion: 3,
              // scroll: 1
            } 
          })
}
</script>

<style lang="css" scoped>
.hyperlink-observ-div span {
  font-weight: 600;
}
</style>
export const cohortNames = [
    "BigKids",
    "Bombils",
    "Bimblis",
    "Batatas-1",
    "Batatas-2",
    "Bumbble Bees",
    "Butterfly"
]

export const cohortsIgnoreList = [
  "Batatas",
  "BigKids",
  "BigKids Old"
]

export const educatorNames = [
    'Hetvi','Kshama','Neha', 
    'Nikita','Priyanka', 'Ruchika', 
    'Sai', 'Meghann', 'Anjali',
    'Ashwin', 'Prembhari', 'Rowena',
]

export const competencyNames = [
    "Critical Thinking", "Communication", "Reading & Writing", "Math & Numeracy", 
    "Science Exploration", "Social Studies", "Logic & Reasoning", "Creativity & Innovation", 
    "Digital Literacy", "Visual-Spatial Skills", "Cognitive Skills", "Interdisciplinary Connections", 
    "Technology Skills", "Media Literacy","Emotional Intelligence", "Interpersonal Skills", 
    "Ethics & Values", "Empathy & Perspective", "Conflict Resolution", "Self-Awareness", 
    "Resilience & Coping", "Moral Reasoning","Gross Motor Skills", "Fine Motor Skills", "Physical Wellness", 
    "Nutrition Education", "Safety Awareness", "Body Control & Mindfulness","Visual Arts", "Music & Rhythm", 
    "Drama & Dance", "Design & Making", "Storytelling", "Nature & Environment", "Digital Media Arts", 
    "Photography", "Visual Composition","Cultural Exploration", "Environmental Stewardship", 
    "Community Impact", "Systems Thinking", "Technology's Impact", "Culinary Skills"
]
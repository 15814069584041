<template>
  <div class="py-4 md:ml-8 md:mt-4 h-[100%]">
    <!-- Back button and title -->
    <div class="flex items-center px-4 gap-4 mb-4 md:mb-6">
      <button @click="$router.push({path: '/front-portal/parent-dashboard'})" class="font-medium md:mr-1">
        <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.3125 8.8125C17.8648 8.8125 18.3125 8.36478 18.3125 7.8125C18.3125 7.26022 17.8648 6.8125 17.3125 6.8125V8.8125ZM0.940353 7.10539C0.549829 7.49592 0.549829 8.12908 0.940353 8.51961L7.30432 14.8836C7.69484 15.2741 8.328 15.2741 8.71853 14.8836C9.10905 14.493 9.10905 13.8599 8.71853 13.4694L3.06167 7.8125L8.71853 2.15565C9.10905 1.76512 9.10905 1.13196 8.71853 0.741432C8.328 0.350908 7.69484 0.350908 7.30432 0.741432L0.940353 7.10539ZM17.3125 6.8125L1.64746 6.8125V8.8125L17.3125 8.8125V6.8125Z"
            fill="#6B7280" />
        </svg>
      </button>
      <h2 class="font-[500] text-[18px] md:text-[28px] font-[400]">Weekly Notes</h2>
    </div>

    <!-- Filters section -->
    <div class="flex flex-wrap gap-4 px-4 mb-4 md:mb-6 items-center">

      <!-- Date range and pagination controls for Desktop -->
      <div class="hidden md:flex items-center justify-between">
        <div class="flex items-center">
          <!-- Left arrow -->
          <button @click="previousWeek" :disabled="!hasPrevious"
            class="flex justify-center items-center w-6 h-[34px] md:h-[44.5px] bg-[#FDD835] rounded-l-md">
            <span class="">
              <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.63085 1.7715C7.65995 1.80052 7.68304 1.83501 7.69879 1.87298C7.71455 1.91094 7.72266 1.95164 7.72266 1.99275C7.72266 2.03385 7.71455 2.07455 7.69879 2.11252C7.68304 2.15048 7.65995 2.18497 7.63085 2.214L4.10147 5.74275L7.63085 9.2715C7.6599 9.30055 7.68295 9.33504 7.69867 9.37301C7.7144 9.41097 7.72249 9.45166 7.72249 9.49275C7.72249 9.53384 7.7144 9.57452 7.69867 9.61249C7.68295 9.65045 7.6599 9.68494 7.63085 9.714C7.60179 9.74305 7.5673 9.7661 7.52934 9.78182C7.49138 9.79755 7.45069 9.80564 7.4096 9.80564C7.36851 9.80564 7.32782 9.79755 7.28986 9.78182C7.2519 9.7661 7.2174 9.74305 7.18835 9.714L3.43835 5.964C3.40925 5.93497 3.38616 5.90048 3.3704 5.86252C3.35465 5.82455 3.34654 5.78385 3.34654 5.74275C3.34654 5.70164 3.35465 5.66094 3.3704 5.62298C3.38616 5.58501 3.40925 5.55052 3.43835 5.5215L7.18835 1.7715C7.21738 1.74239 7.25186 1.7193 7.28983 1.70355C7.32779 1.6878 7.36849 1.67969 7.4096 1.67969C7.4507 1.67969 7.4914 1.6878 7.52937 1.70355C7.56733 1.7193 7.60182 1.74239 7.63085 1.7715Z"
                  fill="#1F2937" stroke="black" stroke-width="0.215157" />
              </svg>
            </span>
          </button>

          <!-- Date range -->
          <div class="flex justify-center items-center h-[34px] md:h-[44.5px] bg-[#FDD835] px-1">
            <span class="text-[12px] md:text-[14px] font-medium">
              {{ formatDateRange(currentWeekStart,moment(currentWeekStart).add(6, 'days')) }}
            </span>
             
          </div>

          <!-- Right arrow -->
          <button @click="nextWeek" :disabled="!hasNext"
            class="flex justify-center items-center w-6 h-[34px] md:h-[44.5px] bg-[#FDD835] rounded-r-md">
            <span class="">
              <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M3.209 9.71288C3.17989 9.68385 3.1568 9.64937 3.14105 9.6114C3.1253 9.57343 3.11719 9.53273 3.11719 9.49163C3.11719 9.45052 3.1253 9.40982 3.14105 9.37186C3.1568 9.33389 3.17989 9.29941 3.209 9.27038L6.73837 5.74163L3.209 2.21288C3.17994 2.18382 3.15689 2.14933 3.14117 2.11137C3.12544 2.07341 3.11735 2.03272 3.11735 1.99163C3.11735 1.95054 3.12544 1.90985 3.14117 1.87189C3.15689 1.83393 3.17994 1.79943 3.209 1.77038C3.23805 1.74132 3.27254 1.71828 3.31051 1.70255C3.34847 1.68683 3.38916 1.67873 3.43025 1.67873C3.47134 1.67873 3.51202 1.68683 3.54999 1.70255C3.58795 1.71828 3.62244 1.74132 3.6515 1.77038L7.4015 5.52038C7.4306 5.54941 7.45369 5.58389 7.46944 5.62186C7.4852 5.65982 7.4933 5.70052 7.4933 5.74163C7.4933 5.78273 7.4852 5.82343 7.46944 5.8614C7.45369 5.89937 7.4306 5.93385 7.4015 5.96288L3.6515 9.71288C3.62247 9.74198 3.58798 9.76507 3.55002 9.78082C3.51205 9.79658 3.47135 9.80469 3.43025 9.80469C3.38914 9.80469 3.34844 9.79658 3.31047 9.78082C3.27251 9.76507 3.23802 9.74198 3.209 9.71288Z"
                  fill="#1F2937" stroke="black" stroke-width="0.215157" />
              </svg>
            </span>
          </button>
        </div>
      </div>

      <!-- Cohort Filter-->
      <div v-if="optionsResponse && optionsResponse.new_cohorts" class="rounded-[8px] w-[40%] md:max-w-[200px]"
        style="border-color: rgba(212, 215, 221, 1);">

        <div class="relative">

          <select id="cohortsSelect" ref="cohortsSelect" multiple data-hs-select='{
          "placeholder": "Cohorts",
          "toggleTag": "<button type=\"button\"></button>",
          "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
          "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
          "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
          "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
          }' class="hidden">
            <option v-for="cohort in optionsResponse.new_cohorts" :key="cohort.id" :value="cohort.id">
              {{ cohort.name }}
            </option>
          </select>

          <div class="absolute top-1/2 end-3 -translate-y-1/2">
            <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
              width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round">
              <path d="m7 15 5 5 5-5" />
              <path d="m7 9 5-5 5 5" />
            </svg>
          </div>
        </div>
        <!-- End Select -->
      </div>


      <!-- Sort by filter -->
      <div class="rounded-[8px] w-[40%] md:max-w-[200px]" style="border-color: rgba(212, 215, 221, 1);">

        <div class="relative">
          <select v-model="selectedSort" id="sortingSelect" ref="sortingSelect" data-hs-select='{
                    "placeholder": "Sort",
                    "toggleTag": "<button type=\"button\"></button>",
                    "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] focus:outline-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                    "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                    "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                    "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                    }' class="hidden">
            <option value="desc">Sort: New to old</option>
            <option value="asc">Sort: Old to New</option>
          </select>

          <div class="absolute top-1/2 end-3 -translate-y-1/2">
            <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
              width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round">
              <path d="m7 15 5 5 5-5" />
              <path d="m7 9 5-5 5 5" />
            </svg>
          </div>
        </div>
        <!-- End Select -->
      </div>
    </div>

    <!-- Always render the date range and pagination controls, regardless of content -->
    <div class="md:hidden flex items-center px-4 justify-between mb-3">
      <div class="flex items-center">
        <!-- Left arrow -->
        <button @click="previousWeek" :disabled="!hasPrevious"
          class="flex justify-center items-center w-6 h-[34px] md:h-[38px] bg-[#FDD835] rounded-l-md">
          <span class="">
            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M7.63085 1.7715C7.65995 1.80052 7.68304 1.83501 7.69879 1.87298C7.71455 1.91094 7.72266 1.95164 7.72266 1.99275C7.72266 2.03385 7.71455 2.07455 7.69879 2.11252C7.68304 2.15048 7.65995 2.18497 7.63085 2.214L4.10147 5.74275L7.63085 9.2715C7.6599 9.30055 7.68295 9.33504 7.69867 9.37301C7.7144 9.41097 7.72249 9.45166 7.72249 9.49275C7.72249 9.53384 7.7144 9.57452 7.69867 9.61249C7.68295 9.65045 7.6599 9.68494 7.63085 9.714C7.60179 9.74305 7.5673 9.7661 7.52934 9.78182C7.49138 9.79755 7.45069 9.80564 7.4096 9.80564C7.36851 9.80564 7.32782 9.79755 7.28986 9.78182C7.2519 9.7661 7.2174 9.74305 7.18835 9.714L3.43835 5.964C3.40925 5.93497 3.38616 5.90048 3.3704 5.86252C3.35465 5.82455 3.34654 5.78385 3.34654 5.74275C3.34654 5.70164 3.35465 5.66094 3.3704 5.62298C3.38616 5.58501 3.40925 5.55052 3.43835 5.5215L7.18835 1.7715C7.21738 1.74239 7.25186 1.7193 7.28983 1.70355C7.32779 1.6878 7.36849 1.67969 7.4096 1.67969C7.4507 1.67969 7.4914 1.6878 7.52937 1.70355C7.56733 1.7193 7.60182 1.74239 7.63085 1.7715Z"
                fill="#1F2937" stroke="black" stroke-width="0.215157" />
            </svg>
          </span>
        </button>

        <!-- Date range -->
        <div class="flex justify-center items-center h-[34px] md:h-[38px] bg-[#FDD835] px-1">
          <span class="text-[13px] md:text-[14px] font-medium">{{ formatDateRange(currentWeekStart,
            moment(currentWeekStart).add(6, 'days')) }}</span>
        </div>

        <!-- Right arrow -->
        <button @click="nextWeek" :disabled="!hasNext"
          class="flex justify-center items-center w-6 h-[34px] md:h-[38px] bg-[#FDD835] rounded-r-md">
          <span class="">
            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M3.209 9.71288C3.17989 9.68385 3.1568 9.64937 3.14105 9.6114C3.1253 9.57343 3.11719 9.53273 3.11719 9.49163C3.11719 9.45052 3.1253 9.40982 3.14105 9.37186C3.1568 9.33389 3.17989 9.29941 3.209 9.27038L6.73837 5.74163L3.209 2.21288C3.17994 2.18382 3.15689 2.14933 3.14117 2.11137C3.12544 2.07341 3.11735 2.03272 3.11735 1.99163C3.11735 1.95054 3.12544 1.90985 3.14117 1.87189C3.15689 1.83393 3.17994 1.79943 3.209 1.77038C3.23805 1.74132 3.27254 1.71828 3.31051 1.70255C3.34847 1.68683 3.38916 1.67873 3.43025 1.67873C3.47134 1.67873 3.51202 1.68683 3.54999 1.70255C3.58795 1.71828 3.62244 1.74132 3.6515 1.77038L7.4015 5.52038C7.4306 5.54941 7.45369 5.58389 7.46944 5.62186C7.4852 5.65982 7.4933 5.70052 7.4933 5.74163C7.4933 5.78273 7.4852 5.82343 7.46944 5.8614C7.45369 5.89937 7.4306 5.93385 7.4015 5.96288L3.6515 9.71288C3.62247 9.74198 3.58798 9.76507 3.55002 9.78082C3.51205 9.79658 3.47135 9.80469 3.43025 9.80469C3.38914 9.80469 3.34844 9.79658 3.31047 9.78082C3.27251 9.76507 3.23802 9.74198 3.209 9.71288Z"
                fill="#1F2937" stroke="black" stroke-width="0.215157" />
            </svg>
          </span>
        </button>
      </div>
    </div>

    <!-- Loading state -->
    <div v-if="loading" class="flex justify-center px-4 items-center h-64">
      <div style="color: #FDD835"
        class="animate-spin inline-block size-12 border-[3px] border-current border-t-transparent text-yellow-600 rounded-full">
        <span class="sr-only">Loading...</span>
      </div>
    </div>


    <!-- Show empty state if no observations -->
    <div v-if="!observations.length" class="text-center py-8 px-4 text-gray-500">
      No observations found for this week
    </div>

    <!-- Content -->
    <div v-else class="space-y-4 px-4  w-full overflow-auto h-[78%] md:h-[75%]">
      <div v-for="(group, weekRange) in groupedObservations" :key="weekRange">
        <!-- Date range and pagination controls -->
        <div class="flex items-center justify-between mb-3">

        </div>

        <!-- Observations -->
        <div v-for="observation in processedObservations" :key="observation.name"
          class="p-4 border border-gray-200 rounded-xl mb-4">
          <!-- Cohort badge -->
          <div class="inline-block px-1.5 py-1 bg-[#FDD835] rounded text-[14px] md:text-[15px] mb-4">
            {{ observation.cohort_name }}
          </div>

          <!-- Render each segment -->
          <div v-for="(segment, index) in observation.segments" :key="index" class="mb-4 last:mb-0">
            <!-- Header (if exists) -->
            <div v-if="segment.header" class="font-medium text-gray-800 mb-2 text-[16px] md:text-[17px]">
              {{ segment.header }}
            </div>

            <!-- Content -->
            <div class="mr-2 text-[14px] md:text-[15px] text-gray-600">
              {{ segment.content }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, inject, computed, watch, nextTick } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import moment from 'moment';

// Inject Frappe call method
const $call = inject('$call');
const route = useRoute();
const router = useRouter();

// State
const loading = ref(true);
const observations = ref([]);
const currentPage = ref(1);
const pageSize = ref(10);
const totalCount = ref(0);
const observationId = ref(null);

// Replace page-related refs with date tracking
const currentWeekStart = ref(moment().isoWeekday(1));
const hasNext = ref(false);
const hasPrevious = ref(false);


// Filter states
const selectedCohorts = ref([]);
const selectedSort = ref('desc');
const direction = ref('current');


const props = defineProps({
  optionsResponse: Object,
  userDetails: Object,
  userRoleView: {
    type: String,
    required: true,
  }
});

// Computed
const totalPages = computed(() => {
  return Math.ceil(totalCount.value / pageSize.value);
});

// To extract out the Header
// Add this inside the <script setup> section, with your other computed properties
const processedObservations = computed(() => {
  return observations.value.map(observation => {
    const summary = observation.summary;

    // Updated regex to match content inside {{...}}
    const headerRegex = /{{([^}]+)}}/g;

    // Split the text into segments
    const segments = [];
    let lastIndex = 0;
    let match;

    // Find all headers and their corresponding content
    while ((match = headerRegex.exec(summary)) !== null) {
      // If there's text before this header, add it to the previous segment
      if (lastIndex < match.index && segments.length > 0) {
        segments[segments.length - 1].content += summary.substring(lastIndex, match.index).trim();
      }

      // Add new segment with this header (without braces)
      segments.push({
        header: match[1].trim(), // Extract content inside the braces
        content: ''
      });

      lastIndex = match.index + match[0].length;
    }

    // Add remaining content after the last header
    if (lastIndex < summary.length && segments.length > 0) {
      segments[segments.length - 1].content += summary.substring(lastIndex).trim();
    }

    // If no headers found, create a single segment with full content
    if (segments.length === 0) {
      segments.push({
        header: null,
        content: summary
      });
    }

    return {
      ...observation,
      segments
    };
  });
});


const groupedObservations = computed(() => {
  const grouped = {};

  observations.value.forEach(obs => {
    const weekStart = moment(obs.start_date).startOf('week').format('Do MMM');
    const weekEnd = moment(obs.start_date).endOf('week').format('Do MMM');
    const weekRange = `${weekStart} - ${weekEnd}`;

    if (!grouped[weekRange]) {
      grouped[weekRange] = [];
    }
    grouped[weekRange].push(obs);
  });

  // Sort weeks in descending order
  return Object.fromEntries(
    Object.entries(grouped).sort((a, b) => {
      return moment(b[0].split('-')[0]) - moment(a[0].split('-')[0]);
    })
  );
});


const formatDateRange = (startDate, endDate) => {
  return `${moment(startDate).format('MMM Do')} - ${moment(endDate).format('MMM Do')}`;
};


// Replace fetch method
const fetchObservations = async () => {
  loading.value = true;
  try {
    // Prepare API call parameters
    const params = {
      direction: direction.value,
      reference_date: currentWeekStart.value.format('YYYY-MM-DD'),
      sort_order: selectedSort.value
    };

    // Add cohort filters if selected
    if (selectedCohorts.value && selectedCohorts.value.length > 0) {
      params.cohort_ids = selectedCohorts.value;
    }

    // If we have an observation_id and this is the initial load, include it
    if (observationId.value && direction.value === 'current') {
      params.observation_id = observationId.value;
    }

    const response = await $call(
      'curriculum_planner.curriculum_planner.doctype.observation_summaries.api.get_observation_summaries_for_parents',
      params
    );

    console.log("this is fetch observaion summary response in Observation Summary Page-", response)

    observations.value = response.summaries;
    hasNext.value = response.has_next;
    hasPrevious.value = response.has_previous;

    // Update current week based on response
    currentWeekStart.value = moment(response.week_start);

    // Force currentWeekStart to Monday
    currentWeekStart.value = currentWeekStart.value.isoWeekday(1);

    // If we had an observation_id, scroll to it
    if (observationId.value && direction.value === 'current') {
      // You might want to add this method to scroll to the specific observation
      nextTick(() => {
        scrollToObservation(observationId.value);
      });
    }

    // Reset direction back to current after fetch
    direction.value = 'current';


  } catch (error) {
    console.error('Error fetching observations:', error);
  } finally {
    loading.value = false;
  }
};

// Replace pagination methods
const nextWeek = () => {
  if (hasNext.value) {
    direction.value = 'next';
    fetchObservations('next');
  }
};

const previousWeek = () => {
  if (hasPrevious.value) {
    direction.value = 'previous';
    fetchObservations('previous');
  }
};



// Helper method to scroll to specific observation
const scrollToObservation = (id) => {
  const element = document.getElementById(`observation-${id}`);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};





// Watchers
watch(currentPage, () => {
  fetchObservations();
});

watch(
  () => route.query.observation_id,
  (newId) => {
    if (newId) {
      observationId.value = newId;
      currentPage.value = 1;
      fetchObservations();
    }
  }
);


// Watcher for filter initialization (your existing code)
watch(() => props.optionsResponse,
  (newVal, oldVal) => {
    if (newVal) {
      setTimeout(() => {
        window.HSStaticMethods.autoInit();
        initializeFilters();
      }, 100);
    }
  },
  { immediate: true }
);

// Initialize filters function
const initializeFilters = () => {
  // Initialize cohorts select
  const cohortsSelectElement = window.HSSelect.getInstance('#cohortsSelect');
  if (cohortsSelectElement) {
    cohortsSelectElement.on('change', (value) => {
      console.log('Selected cohorts:', value);
      selectedCohorts.value = value;
      // Reset to current week when filters change
      direction.value = 'current';
      fetchObservations();
    });
  }

  // Initialize sort select
  const sortSelectElement = window.HSSelect.getInstance('#sortingSelect');
  if (sortSelectElement) {
    sortSelectElement.on('change', (value) => {
      console.log('Sort direction:', value);
      selectedSort.value = value;
      fetchObservations();
    });
  }
};

// Lifecycle hooks
onMounted(() => {
  if (route.query.observation_id) {
    observationId.value = route.query.observation_id;
  }
  fetchObservations();
});
</script>

<style scoped>
.tag-badge {
  display: inline-flex;
  align-items: center;
  line-height: 1;
}

/* Add any additional custom styles here */
</style>
<template>
  <div v-if="props.unlinkedData?.length" class="flex flex-col bg-[#FFF6E9] px-2 py-4 rounded-md gap-3 md:w-11/12 xl:w-4/5">
    <div class="hs-collapse-toggle flex justify-between items-start cursor-pointer" aria-expanded="false" 
      aria-controls="hs-show-hide-collapse-heading" data-hs-collapse="#hs-show-hide-collapse-heading"
      id="hs-show-hide-collapse" >
      <div>
        <p class="text-gray-800 font-medium text-[13px] md:text-lg dark:text-neutral-400 flex gap-1 md:gap-3 items-center">
          <span>You have {{ unlinkedEvents.length }} pending Observations!</span>
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.737 20.8544L15.6432 4.94036C15.4449 4.59048 15.1574 4.29946 14.8099 4.09699C14.4625 3.89452 14.0675 3.78784 13.6653 3.78784C13.2632 3.78784 12.8682 3.89452 12.5207 4.09699C12.1733 4.29946 11.8857 4.59048 11.6874 4.94036L2.5937 20.8544C2.39327 21.2015 2.28818 21.5955 2.28907 21.9963C2.28996 22.3971 2.39679 22.7905 2.59875 23.1368C2.80071 23.483 3.09061 23.7696 3.43907 23.9677C3.78753 24.1658 4.18216 24.2682 4.58296 24.2646H22.7705C23.1693 24.2642 23.5611 24.1588 23.9064 23.9591C24.2516 23.7594 24.5383 23.4724 24.7376 23.1268C24.9368 22.7813 25.0417 22.3894 25.0416 21.9906C25.0415 21.5917 24.9364 21.1999 24.737 20.8544Z" stroke="#FF9900" stroke-width="1.13672" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.6797 10.624L13.6933 15.7964" stroke="#FF9900" stroke-width="1.70508" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.6797 19.7178H13.6909" stroke="#FF9900" stroke-width="2.27344" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </p>
      </div>
      <button type="button" class="inline-flex items-center gap-x-1 text-sm 
        font-semibold rounded-lg border border-transparent text-gray-600 decoration-2 
        hover:text-gray-700 hover:underline focus:outline-none focus:underline focus:text-gray-700 
        disabled:opacity-50 disabled:pointer-events-none dark:text-gray-500 dark:hover:text-gray-600 dark:focus:text-gray-600" 
        >
        <svg class="hs-collapse-open:rotate-180 shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <path d="m6 9 6 6 6-6"></path>
        </svg>
      </button>
    </div>
    <div id="hs-show-hide-collapse-heading" class="hs-collapse hidden w-full overflow-hidden 
      transition-[height] duration-300 flex flex-col gap-6" aria-labelledby="hs-show-hide-collapse">
      <p class="text-[12px] md:text-sm">Tap on the + button next to them to start creating</p>
      <div class="flex flex-col gap-2">
        <template v-for="event in unlinkedEvents">
          <div class="flex gap-4 font-medium text-[12px] md:text-sm items-center">
            <span>
              <span class="hidden md:inline">{{ weekdayFormatDateShort(event.start) }} </span>
              <span class="md:hidden inline">{{ formatDateToDayMonth(event.start) }} </span>
              <span class="hidden md:inline"> ({{ event.session }}): </span>
              <span class="md:hidden inline"> ({{ event.session === 'Morning' ? 'AM' : 'PM' }}): </span>
              <span> {{ event.activity_name || event.name1 }} -</span>
              <span>
                <span v-for="(cohort, index) in event.cohort">
                  <span v-if="index > 0">, </span>
                  {{ findValueByName(userDetails?.school_info.cohorts, cohort.link_to_cohort, 'cohort_name') }}
                </span>
              </span>
            </span>
            <button class="bg-[#FFF6E9]" @click="() => openObservationModal(event)">
              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.0391 22.6748C17.5619 22.6748 22.0391 18.1977 22.0391 12.6748C22.0391 7.15196 17.5619 2.6748 12.0391 2.6748C6.51621 2.6748 2.03906 7.15196 2.03906 12.6748C2.03906 18.1977 6.51621 22.6748 12.0391 22.6748Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.03906 12.6748H16.0391" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.0391 8.6748V16.6748" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
        </template>
      </div>
    </div>
    <ActivityObservationModal v-if="childModalActive" modalName="activity-form-modal-not-added" :userData="userDetails" 
      :reloadFn="reloadChildModal" :reloadObservations="props.reloadObservations" confirmModalName="observations-not-added"
      :autoFillData="autoFillData" @success:event="(event_id) => eventsCreated.push(event_id)" />
    <FormConfirmModal :createAnotherFn="addAnotherFn" modalName="observations-not-added" :formType="currentObservType" />
  </div>
</template>

<script setup>
import { 
  findValueByName, weekdayFormatDateShort, findNameByValue
} from '@/lib/utils.js';
import { computed, inject, ref } from 'vue';
import ActivityObservationModal from '@/components/observations/forms/ActivityObservationModal.vue'
import { cn } from '@/components/ui/utils.js';
import { HSOverlay } from 'preline';
import FormConfirmModal from '@/components/observations/forms/FormConfirmModal.vue';

const props = defineProps(['unlinkedData'])

console.log("notadded unlinked", props.unlinkedData)
const userDetails = inject('userDetails')

const eventsCreated = ref([])

const unlinkedEvents = computed(() => {
  if (!props.unlinkedData) return []
  return props.unlinkedData.filter((event) => !eventsCreated.value.includes(event.name))
})

function formatDateToDayMonth(dateString) {
  const date = new Date(dateString); // Convert to Date object
  const day = date.getDate().toString().padStart(2, '0'); // Get day and pad to 2 digits
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get month (0-indexed) and pad
  return `${day}/${month}`; // Return in "DD/MM" format
}

const childModalActive = ref(true)
const reloadChildModal = () => {
  console.log('Reloading Child observation modal')
  childModalActive.value = false
  setTimeout(() => childModalActive.value = true, 100)
}

const autoFillData = ref({})

const openObservationModal = (event) => {
  console.log("opening observation modal for event", event)
  autoFillData.value = {
    cohorts: event.cohort.map((cohort) => cohort.link_to_cohort),
    event_link: event.name,
    date: event.start,
    session: `${event.session} session`,
  };
  HSOverlay.open(`#hs-activity-form-modal-not-added`)
}

const addAnotherFn = () => {
  autoFillData.value = {}
  HSOverlay.open(`#hs-activity-form-modal-not-added`)
}
</script>
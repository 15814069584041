<template>
  
  <!-- <aside ref="sidebar" :class="['hide-scrollbar bg-customSidebar transition-all duration-500 linear w-full fixed bottom-0 md:min-h-auto md:static md:w-auto md:flex-col flex flex-row items-center justify-center md:justify-center px-4 md:h-[91.5vh]',isOpen ? 'md:w-[170px] lg:w-[220px] md:px-0' : 'md:w-[130px]']"> -->
       <aside  ref="sidebar" :style="sidebarStyle" class="hide-scrollbar bg-customSidebar w-full fixed bottom-0 md:min-h-auto md:static md:flex-col flex flex-row items-center justify-center md:justify-center px-4 md:h-[91.5vh] z-30" >

      <button @click="toggleSidebar" class="hidden md:flex absolute right-[-8px] top-2.5 round px-2 rounded-full bg-[#FDD835]">
        {{ isOpen ? '<' : '>' }}
      </button>
  

    <!--Menu for Educators and Admins  -->
    <div v-if="this.roleView == 'Educator'||this.roleView == 'System Manager'" class="flex flex-row items-center md:flex-col md:items-center md:pb-[] overflow-auto md:gap-4 lg:gap-4" :class="[isOpen ? 'w-[85%]':'']">
     
      <!-- Dashboard button -->
      <SidebarButton label="Dashboard" :isMenuOpen="isOpen" :path="dashboardPath" :iconSelected="DashboardIconUnselected" :iconUnselected="DashboardIconUnselected" :disabled="isGuestFacilitator" :alternativeClick="handleAlternativeClick"/>
     
      <!--Schedule dropdown  -->
      <nav class="hs-accordion-group w-full flex flex-col flex-wrap hs-tooltip inline-block [--trigger:hover] [--placement:right]" data-hs-accordion-always-open :class="[isOpen ? '':'']">
  
         <!-- Schedule -- Calendar, Planweek -->
         <ul class="hs-accordion" id="schedule-accordion">
            <button @click="checkSidebarToggle" type="button" class="hs-accordion-toggle hs-accordion-active:hover:bg-[#FDD835] hs-accordion-active:bg-[#FDD835] w-full text-start flex items-center h-[52px] pl-4 text-sm text-gray-700 rounded-lg hover: focus:outline-none focus:bg-[]" aria-expanded="true" aria-controls="account-accordion">
              
              <img :src="CalendarIconUnselected" alt="" class="h-[34px] ">
     

              <span v-if="isOpen" class="ml-7 font-semibold text-xs text-black">Schedule</span>
              
            </button>

            <div id="schedule-Submenu-accordion" class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300 hidden" role="region" aria-labelledby="account-accordion">
              <ul class="pt-2 ps-2">

                <!-- calendar -->
                <router-link to="/front-portal/calendar" @click="closeModal">
                    <li>
                    <div class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-[#FDD835] focus:outline-none focus:bg-[#FDD835] dark:bg-neutral-800 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300" href="#">
                        Calendar
                    </div>
                    </li>
                </router-link> 

                <!-- Plan Week -->
                <router-link to="/front-portal/calendar/plan-week" @click="closeModal">
                    <li>
                    <a class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-[#FDD835] focus:outline-none dark:bg-neutral-800 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300" href="#">
                        Plan Week
                    </a>
                    </li>
                </router-link> 
                 

                <!-- Lunch Menu -->
                <router-link to="/front-portal/lunch-menu" @click="closeModal">
                    <li>
                    <a class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-[#FDD835] focus:outline-none dark:bg-neutral-800 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300" href="#">
                        Lunch Menu
                    </a>
                    </li>
                </router-link> 
   

                  <!-- landscape map -->
                  <router-link to="/front-portal/curriculum-landscape-map" @click="closeModal">
                      <li>
                      <div class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-[#FDD835] focus:outline-none focus:bg-[#FDD835] dark:bg-neutral-800 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300" href="#">
                          Landscape Map
                      </div>
                      </li>
                  </router-link> 

                <!-- landscape table-->
                <!-- <router-link to="/front-portal/curriculum-landscape-table" @click="closeModal">
                    <li>
                    <div class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-[#FDD835] focus:outline-none focus:bg-[#FDD835] dark:bg-neutral-800 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300" href="#">
                        Landscape Table
                    </div>
                    </li>
                </router-link>  -->
                
              </ul>
            </div>
          </ul>

        <span v-if="!isOpen" class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute left-20 invisible z-10 py-1.5 px-4 bg-[#FDD835] border text-sm font-medium text-black rounded-lg shadow-md before:content-[''] before:absolute before:top-1/2 before:right-full before:-translate-y-1/2 before:border-8 before:border-y-transparent before:border-l-transparent before:border-r-[#FDD835]" role="tooltip">
          Schedule
        </span>  
         
      </nav>    
  
      <!-- Observation -->
      <SidebarButton label="Observations" path="/front-portal/observations" :isMenuOpen="isOpen" :iconSelected="ObservationsIconUnselected" :iconUnselected="ObservationsIconUnselected" />

      <!-- Activities -->
      <SidebarButton label="Activities" path="/front-portal/activities" :isMenuOpen="isOpen" :iconSelected="ActivitiesIconUnselected" :iconUnselected="ActivitiesIconUnselected"/>

      <!-- <SidebarButton label="Learners" :isMenuOpen="isOpen" :path="learnersPath"  :iconSelected="LearnersIconuUnselected" :iconUnselected="LearnersIconUnselected" :disabled="isGuestFacilitator" :alternativeClick="handleAlternativeClick"/> -->

      <!-- Learners -- Learners profiles, Learner Reports, Photo Essays -->
      <nav class="hs-accordion-group w-full flex flex-col flex-wrap hs-tooltip inline-block [--trigger:hover] [--placement:right]" data-hs-accordion-always-open :class="[isOpen ? '':'']">
        <ul class="hs-accordion">
              <button @click="checkSidebarToggle" type="button" class="hs-accordion-toggle hs-accordion-active:hover:bg-[#FDD835] hs-accordion-active:bg-[#FDD835] w-full text-start flex items-center h-[52px] pl-4 text-sm text-gray-700 rounded-lg hover: focus:outline-none focus:bg-[]" aria-expanded="true" aria-controls="account-accordion">
                
                <img :src="LearnersIconUnselected" alt="" class="h-[34px] ">
     

                <span v-if="isOpen" class="ml-7 font-semibold text-xs text-black">Learners</span>

              </button>

              <div class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300 hidden" role="region" aria-labelledby="account-accordion">
                <ul class="pt-2 ps-2">
                  <!-- Learner Reports  -->
                  <router-link to="/front-portal/observations/report" @click="closeModal">
                        <li>
                        <a class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-[#FDD835] focus:outline-none dark:bg-neutral-800 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300" href="#">
                          Learner Reports 
                        </a>
                        </li>
                    </router-link> 

                    <!-- Photo Essays  -->
                    <router-link to="/front-portal/photo-essays" @click="closeModal">
                        <li>
                        <a class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-[#FDD835] focus:outline-none dark:bg-neutral-800 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300" href="#">
                          Photo Essays
                        </a>
                        </li>
                    </router-link> 

                    <!-- Learners List -->
                    <router-link to="/front-portal/learners-list" @click="closeModal">
                        <li>
                        <a class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-[#FDD835] focus:outline-none dark:bg-neutral-800 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300" href="#">
                          Learners List
                        </a>
                        </li>
                    </router-link> 
                </ul>
              </div>
        </ul>

        <span v-if="!isOpen" class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute left-20 invisible z-10 py-1.5 px-4 bg-[#FDD835] border text-sm font-medium text-black rounded-lg shadow-md before:content-[''] before:absolute before:top-1/2 before:right-full before:-translate-y-1/2 before:border-8 before:border-y-transparent before:border-l-transparent before:border-r-[#FDD835]" role="tooltip">
          Learners
        </span>
      </nav>

      <SidebarButton label="Micro Learning" path="/front-portal/educators" :isMenuOpen="isOpen" :iconSelected="quick_learnings_unselected" :iconUnselected="quick_learnings_unselected"/>
     
      <SidebarButton label="Worksheets" path="/front-portal/worksheets" :iconSelected="worksheets_new_logo" :isMenuOpen="isOpen" :iconUnselected="worksheets_new_logo" :disabled="isGuestFacilitator" :alternativeClick="handleAlternativeClick"/>

      <!-- <SidebarButton label="Settings" path="/front-portal/settings" :iconSelected="SettingsIconSelected" :iconUnselected="SettingsIconUnselected" /> -->

      <SidebarButton label="Resources" path="/front-portal/resources" :isMenuOpen="isOpen" :iconSelected="resources_logo" :iconUnselected="resources_logo" :disabled="isGuestFacilitator" :alternativeClick="handleAlternativeClick"/>
  
       <!-- More -->
      <nav class="hs-accordion-group w-full flex flex-col flex-wrap hs-tooltip inline-block" data-hs-accordion-always-open :class="[isOpen ? '':'[--trigger:hover] [--placement:right]']">
        <ul id="more-accordian" class="hs-accordion">
              <button @click="checkSidebarToggle" type="button" class="hs-accordion-toggle hs-accordion-active:hover:bg-[#FDD835] hs-accordion-active:bg-[#FDD835] w-full text-start flex items-center h-[52px] pl-5 text-sm text-gray-700 rounded-lg hover: focus:outline-none focus:bg-[]" aria-expanded="true" aria-controls="account-accordion">
                <img :src="moreIcon" alt="" class="h-[34px] ">
     

                <span v-if="isOpen" class="ml-7 font-semibold text-xs text-black">More</span>


              </button>

              <div class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300 hidden" role="region" aria-labelledby="account-accordion">
                <ul class="pt-2 ps-2 mb-12">

                  <!-- Events -->
                  <!-- <li><div class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-[#FDD835] dark:hover:bg-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-300" href="#">
                    Events
                  </div></li> -->

                  <!-- About us-->
                  <!-- <li><a class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-[#FDD835] dark:hover:bg-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-300" href="#">
                    About Us
                  </a></li> -->

                  <!-- Privacy policy-->  
                  <router-link to="/front-portal/privacy-policy" @click="closeModal">
                    <li>
                        <div class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-[#FDD835] dark:hover:bg-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-300">
                        Privacy Policy
                        </div>
                    </li>
                  </router-link> 

                  <!-- Tems of use-->  
                  <router-link to="/front-portal/terms-of-use" @click="closeModal">
                    <li>
                        <div class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-[#FDD835] dark:hover:bg-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-300">
                        Terms of use
                        </div>
                    </li>
                  </router-link> 

                  <!-- Logout button -->
                  <button v-if="$auth.isLoggedIn" @click="this.$auth.logout()" class="py-3 w-[43%] bg-black text-sm text-white rounded-md cursor-pointer hover:bg-gray-400 block ml-2 mt-2">
                      Log out
                  </button>

                </ul>
              </div>
        </ul>
        <span v-if="!isOpen" class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute left-20 invisible z-10 py-1.5 px-4 bg-[#FDD835] border text-sm font-medium text-black rounded-lg shadow-md before:content-[''] before:absolute before:top-1/2 before:right-full before:-translate-y-1/2 before:border-8 before:border-y-transparent before:border-l-transparent before:border-r-[#FDD835]" role="tooltip">
          More
        </span> 
      </nav>

      <!-- Switch user role view -->
      <div v-if="userDetails.user_roles.includes('Parent') && userDetails.user_roles.length > 1"  class="w-full flex flex-col flex-wrap focus:outline-none" :class="[isOpen ? '':'items-center pl-1']">
        <div v-if="!isOpen" @click="toggleSidebar" class="font-semibold text-xs text-black hover:cursor-pointer">View as</div>
        <!-- view as svg -->
        <div v-if="isOpen" class="flex flex-row items-center gap-5 ml-4">
          <svg width="33" height="33" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.0747 9.25586C13.2596 9.25586 7.2946 12.6215 2.59867 19.3528C2.46463 19.547 2.39117 19.7767 2.38759 20.0126C2.384 20.2486 2.45045 20.4804 2.57852 20.6786C6.18665 26.3266 12.0719 30.7435 19.0747 30.7435C26.0015 30.7435 32.0076 26.3131 35.6224 20.6525C35.7475 20.4581 35.814 20.2319 35.814 20.0008C35.814 19.7697 35.7475 19.5434 35.6224 19.3491C31.9994 13.7533 25.9493 9.25586 19.0747 9.25586Z" stroke="black" stroke-width="2.38751" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M19.1001 25.9688C22.3966 25.9688 25.0689 23.2965 25.0689 20C25.0689 16.7036 22.3966 14.0312 19.1001 14.0312C15.8037 14.0312 13.1313 16.7036 13.1313 20C13.1313 23.2965 15.8037 25.9688 19.1001 25.9688Z" stroke="black" stroke-width="2.38751" stroke-miterlimit="10"/>
          </svg>
          <div class="font-semibold text-xs text-black">View as</div>
        </div>

        <div v-if="isOpen" @click="handleRoleSwitchConfirmationModal" class="text-white bg-black rounded w-[70%] self-center mt-2 text-center text-xs py-2 px-3 hover:cursor-pointer">{{ roleView === 'Parent' ? 'Guest Facilitator' : 'Parent' }} </div>
      </div>
  
    </div>  
    

    <!-- Parent menu -->
    <div v-if="this.roleView == 'Parent'"  class="flex flex-row items-center gap-2 md:flex-col md:items-center md:pb-[] overflow-auto" :class="[isOpen ? 'w-[85%] md:gap-5':'md:gap-5']">

        <!-- Dashboard button -->
        <SidebarButton label="Dashboard" :isMenuOpen="isOpen" path="/front-portal/parent-dashboard" :iconSelected="DashboardIconUnselected" :iconUnselected="DashboardIconUnselected"/>
        
        <!--Schedule dropdown  -->
        <nav class="hs-accordion-group w-full flex flex-col flex-wrap hs-tooltip inline-block [--trigger:hover] [--placement:right]" data-hs-accordion-always-open :class="[isOpen ? '':'']">
    
            <!-- Schedule -- Calendar, Planweek -->
            <ul class="hs-accordion" id="schedule-accordion">
              <button @click="checkSidebarToggleForDualuser" type="button" class="hs-accordion-toggle hs-accordion-active:hover:bg-[#FDD835] hs-accordion-active:bg-[#FDD835] w-full text-start flex items-center h-[52px] pl-4 text-sm text-gray-700 rounded-lg hover: focus:outline-none focus:bg-[]" aria-expanded="true" aria-controls="account-accordion">
                
                <img :src="CalendarIconUnselected" alt="" class="h-[34px] ">
     

                <span v-if="isOpen" class="ml-7 font-semibold text-xs text-black">Schedule</span>
                
              </button>

              <div id="schedule-Submenu-accordion" class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300 hidden" role="region" aria-labelledby="account-accordion">
                <ul class="pt-2 ps-2">

                  <!-- calendar -->
                  <router-link to="/front-portal/calendar" @click="closeModal">
                      <li>
                      <div class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-[#FDD835] focus:outline-none focus:bg-[#FDD835] dark:bg-neutral-800 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300" href="#">
                          Calendar
                      </div>
                      </li>
                  </router-link> 

                  <!-- Lunch Menu -->
                  <router-link to="/front-portal/lunch-menu" @click="closeModal">
                      <li>
                      <a class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-[#FDD835] focus:outline-none dark:bg-neutral-800 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300" href="#">
                          Lunch Menu
                      </a>
                      </li>
                  </router-link> 

                    <!-- landscape map -->
                  <router-link to="/front-portal/curriculum-landscape-map" @click="closeModal">
                      <li>
                      <div class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-[#FDD835] focus:outline-none focus:bg-[#FDD835] dark:bg-neutral-800 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300" href="#">
                          Landscape Map
                      </div>
                      </li>
                  </router-link> 

                  <!-- landscape table-->
                  <!-- <router-link to="/front-portal/curriculum-landscape-table" @click="closeModal">
                      <li>
                      <div class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-[#FDD835] focus:outline-none focus:bg-[#FDD835] dark:bg-neutral-800 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300" href="#">
                          Landscape Table
                      </div>
                      </li>
                  </router-link>  -->
                  
                </ul>
              </div>
            </ul>

          <span v-if="!isOpen" class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute left-20 invisible z-10 py-1.5 px-4 bg-[#FDD835] border text-sm font-medium text-black rounded-lg shadow-md before:content-[''] before:absolute before:top-1/2 before:right-full before:-translate-y-1/2 before:border-8 before:border-y-transparent before:border-l-transparent before:border-r-[#FDD835]" role="tooltip">
            Schedule
          </span>  
            
        </nav>    
    

        <!-- Learners -- Learners profiles, Learner Reports, Photo Essays -->
        <nav class="hs-accordion-group w-full flex flex-col flex-wrap hs-tooltip inline-block [--trigger:hover] [--placement:right]" data-hs-accordion-always-open :class="[isOpen ? '':'']">
          <ul class="hs-accordion">
                <button @click="checkSidebarToggleForDualuser" type="button" class="hs-accordion-toggle hs-accordion-active:hover:bg-[#FDD835] hs-accordion-active:bg-[#FDD835] w-full text-start flex items-center h-[52px] pl-4 text-sm text-gray-700 rounded-lg hover: focus:outline-none focus:bg-[]" aria-expanded="true" aria-controls="account-accordion">
                  
                  <img :src="LearnersIconUnselected" alt="" class="h-[34px] ">
     

                  <span v-if="isOpen" class="ml-7 font-semibold text-xs text-black">Learners</span>

                </button>

                <div class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300 hidden" role="region" aria-labelledby="account-accordion">
                  <ul class="pt-2 ps-2">

                    <!--  Learner Profiles -->
                    <router-link to="/front-portal/learners" @click="closeModal">
                        <li>
                        <a class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-[#FDD835] focus:outline-none focus:bg-[#FDD835] dark:bg-neutral-800 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300" href="#">
                            Learner Profiles
                        </a>
                        </li>
                    </router-link> 

                    <!-- Learner Reports  -->
                    <router-link to="/front-portal/observations/report" @click="closeModal">
                        <li>
                        <a class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-[#FDD835] focus:outline-none focus:bg-gray-100 dark:bg-neutral-800 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300" href="#">
                          Learner Reports 
                        </a>
                        </li>
                    </router-link> 

                    <!-- Photo Essays  -->
                    <router-link to="/front-portal/photo-essays" @click="closeModal">
                        <li>
                        <a class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-[#FDD835] focus:outline-none focus:bg-gray-100 dark:bg-neutral-800 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300" href="#">
                          Photo Essays
                        </a>
                        </li>
                    </router-link> 

                    <!-- Photo Essays  -->
                    <router-link to="/front-portal/shared-activity-observations" @click="closeModal">
                        <li>
                        <a class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-[#FDD835] focus:outline-none focus:bg-gray-100 dark:bg-neutral-800 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300" href="#">
                          Weekly Notes
                        </a>
                        </li>
                    </router-link> 
                    
                  </ul>
                </div>
          </ul>

          <span v-if="!isOpen" class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute left-20 invisible z-10 py-1.5 px-4 bg-[#FDD835] border text-sm font-medium text-black rounded-lg shadow-md before:content-[''] before:absolute before:top-1/2 before:right-full before:-translate-y-1/2 before:border-8 before:border-y-transparent before:border-l-transparent before:border-r-[#FDD835]" role="tooltip">
            Learners
          </span>
        </nav>

        <SidebarButton label="Micro Learning" path="/front-portal/educators" :isMenuOpen="isOpen" :iconSelected="quick_learnings_unselected"  :iconUnselected="quick_learnings_unselected" />

        <SidebarButton label="Worksheets" path="/front-portal/worksheets" :iconSelected="worksheets_new_logo" :isMenuOpen="isOpen" :iconUnselected="worksheets_new_logo"/>

        <SidebarButton label="Resources" path="/front-portal/resources" :isMenuOpen="isOpen" :iconSelected="resources_logo" :iconUnselected="resources_logo"/>
    
        <!-- More -->
        <nav class="hs-accordion-group w-full flex flex-col flex-wrap hs-tooltip inline-block" data-hs-accordion-always-open :class="[isOpen ? '':'[--trigger:hover] [--placement:right] items-center']">
          <ul id="more-accordian" class="hs-accordion">
                <button @click="checkSidebarToggleForDualuser" type="button" class="hs-accordion-toggle hs-accordion-active:hover:bg-[#FDD835] hs-accordion-active:bg-[#FDD835] w-full text-start flex items-center h-[52px] text-sm text-gray-700 rounded-lg hover: focus:outline-none focus:bg-[]" :class="[isOpen ? 'pl-5':'']" aria-expanded="true" aria-controls="account-accordion">
                 
                  <img :src="moreIcon" alt="" class="h-[34px] ">
     

                  <span v-if="isOpen" class="ml-7 font-semibold text-xs text-black">More</span>


                </button>

                <div class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300 hidden" role="region" aria-labelledby="account-accordion">
                  <ul class="pt-2 ps-2">

                    <!-- Events -->
                    <!-- <li><div class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-[#FDD835] dark:hover:bg-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-300" href="#">
                      Events
                    </div></li> -->

                    <!-- About us-->
                    <!-- <li><a class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-[#FDD835] dark:hover:bg-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-300" href="#">
                      About Us
                    </a></li> -->

                    <!-- Privacy policy-->  
                    <router-link to="/front-portal/privacy-policy" @click="closeModal">
                      <li>
                          <div class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-[#FDD835] dark:hover:bg-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-300">
                          Privacy Policy
                          </div>
                      </li>
                    </router-link> 

                    <!-- Tems of use-->  
                    <router-link to="/front-portal/terms-of-use" @click="closeModal">
                      <li>
                          <div class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-[#FDD835] dark:hover:bg-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-300">
                          Terms of use
                          </div>
                      </li>
                    </router-link> 

                    <!-- Logout button -->
                    <button v-if="$auth.isLoggedIn" @click="this.$auth.logout()" class="py-3 w-[43%] bg-black text-sm text-white rounded-md cursor-pointer hover:bg-gray-400 block ml-2 mt-2">
                        Log out
                    </button>

                  </ul>
                </div>
          </ul>
        </nav>

        <!-- Switch user role view -->
        <div v-if="userDetails.user_roles.includes('Parent') && userDetails.user_roles.length > 1" class="w-full flex flex-col flex-wrap focus:outline-none hover:cursor-pointer" :class="[isOpen ? '':'items-center pl-1']">
          <div v-if="!isOpen" @click="toggleSidebar" class="font-semibold text-xs text-black">View as</div>
          
          <!-- view as svg -->
          <div v-if="isOpen" class="flex flex-row items-center gap-5 ml-4 hover:cursor-text">
            <svg width="33" height="33" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.0747 9.25586C13.2596 9.25586 7.2946 12.6215 2.59867 19.3528C2.46463 19.547 2.39117 19.7767 2.38759 20.0126C2.384 20.2486 2.45045 20.4804 2.57852 20.6786C6.18665 26.3266 12.0719 30.7435 19.0747 30.7435C26.0015 30.7435 32.0076 26.3131 35.6224 20.6525C35.7475 20.4581 35.814 20.2319 35.814 20.0008C35.814 19.7697 35.7475 19.5434 35.6224 19.3491C31.9994 13.7533 25.9493 9.25586 19.0747 9.25586Z" stroke="black" stroke-width="2.38751" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M19.1001 25.9688C22.3966 25.9688 25.0689 23.2965 25.0689 20C25.0689 16.7036 22.3966 14.0312 19.1001 14.0312C15.8037 14.0312 13.1313 16.7036 13.1313 20C13.1313 23.2965 15.8037 25.9688 19.1001 25.9688Z" stroke="black" stroke-width="2.38751" stroke-miterlimit="10"/>
            </svg>
            <div class="font-semibold text-xs text-black">View as</div>
          </div>

          <div v-if="isOpen"  @click="handleRoleSwitchConfirmationModal" class="text-white bg-black rounded w-[70%] self-center mt-2 text-center text-xs py-2 px-3">{{ roleView === 'Parent' ? 'Educator' : 'Parent' }} </div>
        </div>
 
    </div>


     <!--Guest facilitator Menu, does not Open, only contains icons and button with event listener for displaying a restriction modal-->
     <div v-if="this.roleView == 'Guest Facilitator'" class="flex flex-row items-center md:flex-col md:items-center md:pb-4 overflow-auto md:gap-4 lg:gap-4" :class="[isOpen ? 'w-[85%]':'']">
      <!-- Dashboard button -->
      <SidebarButton label="Dashboard" :isMenuOpen="isOpen" :path="dashboardPath" :iconSelected="DashboardIconUnselected" :iconUnselected="DashboardIconUnselected" :disabled="isGuestFacilitator" :alternativeClick="handleAlternativeClick"/>
     
      <!--Schedule dropdown  -->
      <nav class="hs-accordion-group w-full flex flex-col flex-wrap hs-tooltip inline-block [--trigger:hover] [--placement:right]" data-hs-accordion-always-open :class="[isOpen ? '':'']">
  
         <!-- Schedule -- Calendar, Planweek -->
         <ul class="" id="schedule-accordion">
            <button @click="checkSidebarToggle" type="button" class="hs-accordion-toggle hs-accordion-active:hover:bg-[#FDD835] hs-accordion-active:bg-[#FDD835] w-full text-start flex items-center h-[52px] pl-4 text-sm text-gray-700 rounded-lg hover: focus:outline-none focus:bg-[]" aria-expanded="true" aria-controls="account-accordion">
              
              <img :src="CalendarIconUnselected" alt="" class="h-[34px] ">
     

              <span v-if="isOpen" class="ml-7 font-semibold text-xs text-black">Schedule</span>
              
            </button>
          </ul>

        <span v-if="!isOpen" class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute left-20 invisible z-10 py-1.5 px-4 bg-[#FDD835] border text-sm font-medium text-black rounded-lg shadow-md before:content-[''] before:absolute before:top-1/2 before:right-full before:-translate-y-1/2 before:border-8 before:border-y-transparent before:border-l-transparent before:border-r-[#FDD835]" role="tooltip">
          Schedule
        </span>  
         
      </nav>    
  
      <!-- Observation -->
      <SidebarButton label="Observations" path="/front-portal/observations" :isMenuOpen="isOpen" :iconSelected="ObservationsIconUnselected" :iconUnselected="ObservationsIconUnselected" />

      <!-- Activities -->
      <SidebarButton label="Activities" path="/front-portal/activities" :isMenuOpen="isOpen" :iconSelected="ActivitiesIconSelected" :iconUnselected="ActivitiesIconUnselected" :disabled="isGuestFacilitator" :alternativeClick="handleAlternativeClick"/>

      <!-- <SidebarButton label="Learners" :isMenuOpen="isOpen" :path="learnersPath"  :iconSelected="LearnersIconuUnselected" :iconUnselected="LearnersIconUnselected" :disabled="isGuestFacilitator" :alternativeClick="handleAlternativeClick"/> -->

      <!-- Learners -- Learners profiles, Learner Reports, Photo Essays -->
      <nav class="hs-accordion-group w-full flex flex-col flex-wrap hs-tooltip inline-block [--trigger:hover] [--placement:right]" data-hs-accordion-always-open :class="[isOpen ? '':'']">
        <ul>
              <button @click="checkSidebarToggle" type="button" class="hs-accordion-toggle hs-accordion-active:hover:bg-[#FDD835] hs-accordion-active:bg-[#FDD835] w-full text-start flex items-center h-[52px] pl-4 text-sm text-gray-700 rounded-lg hover: focus:outline-none focus:bg-[]" aria-expanded="true" aria-controls="account-accordion">
                
                <img :src="LearnersIconUnselected" alt="" class="h-[34px] ">
     

                <span v-if="isOpen" class="ml-7 font-semibold text-xs text-black">Learners</span>

              </button>
        </ul>

        <span v-if="!isOpen" class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute left-20 invisible z-10 py-1.5 px-4 bg-[#FDD835] border text-sm font-medium text-black rounded-lg shadow-md before:content-[''] before:absolute before:top-1/2 before:right-full before:-translate-y-1/2 before:border-8 before:border-y-transparent before:border-l-transparent before:border-r-[#FDD835]" role="tooltip">
          Learners
        </span>
      </nav>

      <SidebarButton label="Micro Learning" path="/front-portal/educators" :isMenuOpen="isOpen" :iconSelected="quick_learnings_unselected" :iconUnselected="quick_learnings_unselected" :disabled="isGuestFacilitator" :alternativeClick="handleAlternativeClick"/>
     
      <SidebarButton label="Worksheets" path="/front-portal/worksheets" :iconSelected="worksheets_new_logo" :isMenuOpen="isOpen" :iconUnselected="worksheets_new_logo" :disabled="isGuestFacilitator" :alternativeClick="handleAlternativeClick"/>

      <SidebarButton label="Resources" path="/front-portal/resources" :isMenuOpen="isOpen" :iconSelected="resources_logo" :iconUnselected="resources_logo" :disabled="isGuestFacilitator" :alternativeClick="handleAlternativeClick"/>
  
      <!-- More -->
      <nav class="hs-accordion-group w-full flex flex-col flex-wrap hs-tooltip inline-block" data-hs-accordion-always-open :class="[isOpen ? '':'[--trigger:hover] [--placement:right] items-center']">
        <ul id="more-accordian">
              <button @click="checkSidebarToggle" type="button" class="hs-accordion-toggle hs-accordion-active:hover:bg-[#FDD835] hs-accordion-active:bg-[#FDD835] w-full text-start flex items-center h-[52px] text-sm text-gray-700 rounded-lg hover: focus:outline-none focus:bg-[]" :class="[isOpen ? 'pl-5':'']" aria-expanded="true" aria-controls="account-accordion">
                <img :src="moreIcon" alt="" class="h-[34px] ">
     

                <span v-if="isOpen" class="ml-7 font-semibold text-xs text-black">More</span>

              </button>
        </ul>
      </nav>
  
       <!-- Switch user role view -->
       <div v-if="userDetails.user_roles.includes('Parent') && userDetails.user_roles.length > 1" class="w-full flex flex-col flex-wrap focus:outline-none hover:cursor-pointer" :class="[isOpen ? '':'items-center pl-1']">
          <div v-if="!isOpen" @click="toggleSidebar" class="font-semibold text-xs text-black">View as</div>
          
          <!-- view as svg -->
          <div v-if="isOpen" class="flex flex-row items-center gap-5 ml-4 hover:cursor-text">
            <svg width="33" height="33" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.0747 9.25586C13.2596 9.25586 7.2946 12.6215 2.59867 19.3528C2.46463 19.547 2.39117 19.7767 2.38759 20.0126C2.384 20.2486 2.45045 20.4804 2.57852 20.6786C6.18665 26.3266 12.0719 30.7435 19.0747 30.7435C26.0015 30.7435 32.0076 26.3131 35.6224 20.6525C35.7475 20.4581 35.814 20.2319 35.814 20.0008C35.814 19.7697 35.7475 19.5434 35.6224 19.3491C31.9994 13.7533 25.9493 9.25586 19.0747 9.25586Z" stroke="black" stroke-width="2.38751" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M19.1001 25.9688C22.3966 25.9688 25.0689 23.2965 25.0689 20C25.0689 16.7036 22.3966 14.0312 19.1001 14.0312C15.8037 14.0312 13.1313 16.7036 13.1313 20C13.1313 23.2965 15.8037 25.9688 19.1001 25.9688Z" stroke="black" stroke-width="2.38751" stroke-miterlimit="10"/>
            </svg>
            <div class="font-semibold text-xs text-black">View as</div>
          </div>
          <div v-if="isOpen"  @click="handleRoleSwitchConfirmationModal" class="text-white bg-black rounded w-[70%] self-center mt-2 text-center text-xs py-2 px-3">{{ roleView === 'Parent' ? 'Educator' : 'Parent' }} </div>
       </div>
     </div>

  </aside>
</template>

<script>
import SidebarButton from './SidebarButton.vue';

import DashboardIconUnselected from '@/assets/desktop-menu-icons/dashboard-icon.png';

import CalendarIconUnselected from '@/assets/desktop-menu-icons/calendar-icon.png';

import ActivitiesIconUnselected from '@/assets/desktop-menu-icons/activities-icon.png';

import LearnersIconUnselected from '@/assets/desktop-menu-icons/learners-icon.png'; 

import EducatorsIconUnselected from '@/assets/desktop-menu-icons/educators-icon.png'; 

import SettingsIconUnselected from '@/assets/desktop-menu-icons/calendar-icon.png';

import ObservationsIconUnselected from '@/assets/desktop-menu-icons/observations-icon.png';
import quick_learnings_unselected from '@/assets/desktop-menu-icons/micro-learning-icon.png';

import worksheets_unselected from '@/assets/desktop-menu-icons/calendar-icon.png';
import worksheets_new_logo from '@/assets/desktop-menu-icons/worksheet-icon.png';

import resources_logo from '@/assets/desktop-menu-icons/resources-icon.png';
import moreIcon from '@/assets/desktop-menu-icons/more-icon.png';
    

export default {
  inject: ['$auth'],
  components: {
    SidebarButton,
  },
  props: {
    userDetails: {
      type: Object,
      required: true,
    },
  roleView: {
      type: String,
      required: true,
    },
  },
  // inject: ['$userDetails'],
  mounted(){
      console.log("user details and roles recieved in sidebar",this.userDetails)  
      if (this.userDetails && !this.userDetails.is_system_manager && !this.userDetails.user_roles.includes('Educator')) {
        if (this.userDetails.user_roles.includes('Parent')){
        //  this.$refs.sidebar.classList.remove('md:justify-center');
        //  this.$refs.sidebar.classList.add('md:justify-start');
         this.$refs.sidebar.classList.add('md:pt-14');
          } 
    }    
  },
  data() {
    return {
      
      DashboardIconUnselected,
      
      CalendarIconUnselected,
     
      ActivitiesIconUnselected,
     
      LearnersIconUnselected,
      
      EducatorsIconUnselected,
     
      SettingsIconUnselected,
     
      ObservationsIconUnselected,
    
      worksheets_unselected,
     
      quick_learnings_unselected,
      resources_logo,
      worksheets_new_logo,
      moreIcon,

      isOpen: false,

      renderRoleSwitchButton: false,
     
    };
  },
  computed: {
    dashboardPath() {
      if (this.userDetails.is_system_manager || this.userDetails.user_roles.includes('Educator')) {
        return '/front-portal/educator-dashboard';
      } else if (this.userDetails.user_roles.includes('Parent')) {
        return '/front-portal/parent-dashboard';
      } else {
        return '/';  // Fallback path if no specific role matches
      }
    },
    learnersPath() {
      if (this.userDetails.is_system_manager || this.userDetails.user_roles.includes('Educator')) {
        return '/front-portal/photo-essays';
      } else if (this.userDetails.user_roles.includes('Parent')) {
        return '/front-portal/learners';
      } else {
        return '/';  // Fallback path if no specific role matches
      }
    },
    isGuestFacilitator() {
      console.log("this is the guest facilitator role",this.userDetails.user_roles)
      return this.userDetails.user_roles.includes('Guest Facilitator');
    },
    sidebarStyle() {
    let checkScreenSize = '';
    const width = window.innerWidth;
      
    if (width >= 1440) {
      checkScreenSize = 'lg';
    } else if (width >= 768) {
      checkScreenSize = 'md';
    }

    return {
      transition: 'all 0.2s linear',
      width: this.isOpen
        ? (checkScreenSize === 'lg' ? '210px' : checkScreenSize === 'md' ? '195px' : '195px')
        : '100px',
      padding: this.isOpen ? '0px' : '0 16px'
    };
  }
  },
  methods: {
    handleAlternativeClick() {
      // For opening navigation-restriction modal for guest facilitator
      this.$emit('show-modal'); 
    },
    toggleSidebar() {

      this.closeAllSubmenus();
      this.isOpen = !this.isOpen;
      
    },
    checkSidebarToggle(){
      if(this.userDetails.user_roles.includes('Guest Facilitator')){
        this.$emit('show-modal');
      }
      else if (this.isOpen === false) {
        this.isOpen = true;
      }
   },
   checkSidebarToggleForDualuser(){
    if (this.isOpen === false) {
        this.isOpen = true;
      }
   },
   closeAllSubmenus(){
    // Select all elements with the class 'hs-accordion'
    const accordionItems = document.querySelectorAll('.hs-accordion');
    
    // Iterate through each accordion item
    accordionItems.forEach(item => {
      // Remove the 'active' class if present
      item.classList.remove('active');
      const button = item.querySelector('button');
        if (button) {
          button.setAttribute('aria-expanded', 'false');
        }

        const content = item.querySelector('.hs-accordion-content');
        if (content) {
          content.style.display = 'none';
        }
      })

   },
   handleRoleSwitchConfirmationModal(){
    this.$emit('show-role-switch-confirmation-modal'); 
   },
  }
};
</script>

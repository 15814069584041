<template>
  <div class="flex flex-col md:flex-row">
    <div class="grid grid-cols-2 size-64 rounded-xl overflow-hidden shadow-lg">
      <div v-for="(option, index) in answerOptions" :style="{ backgroundColor: optionColors[index] }"
        class="p-2 flex flex-col gap-1 flex-wrap">
        <p v-for="child in studentsGroupedByAns[option] || []" class="text-xs font-medium">
          <!-- {{ child }} -->
          {{ findValueByName(userDetails?.school_info.students, child, 'student_name') }}
        </p>
      </div>
    </div>
    <div class="md:flex-auto md:basis-1/2 flex p-3 md:p-0 md:justify-center">
      <div class="space-y-1">
        <div class="flex gap-2" v-for="(option, index) in displayOptions">
          <div :style="{ backgroundColor: optionColors[index] }" :class="`size-4 shrink-0`" ></div>
          <p class="text-[0.6rem] font-medium">{{ option }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue';
import { findValueByName } from '@/lib/utils'

const props = defineProps(['weeklyChildData', 'selectedCohort'])

const userDetails = inject('userDetails')

const displayOptions = [
  "Played well with others and initiated activities",
  "Enjoyed solo play and created own games",
  "Seemed unsure what to do during free time",
  "Not Sure",
]

const answerOptions = [
  "Plays well with others and initiates activities",
  "Enjoys solo play and creates own games",
  "Seems unsure what to do during free time",
  "Not sure",
]

const optionColors = [
  '#FFC000', '#F58F00', '#EC681B', '#FE965C'
]

const studentsGroupedByAns = computed(() => {
  if (!props.selectedCohort) return {};
  if (!props.weeklyChildData) return {};
  if (!props.weeklyChildData[props.selectedCohort]?.ongoing) return {};
  return props.weeklyChildData[props.selectedCohort]?.ongoing.reduce((acc, observation) => {
    if (!acc[observation.free_play_behavior]) {
      acc[observation.free_play_behavior] = [];
    }

    acc[observation.free_play_behavior].push(observation.student)

    return acc;
  }, {});
}) 
</script>
<template>
    <div>
      <div v-if="isModalOpen" @click.self="closeUploadPhotosModal" class="new-event-form fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-auto">
        <!-- Form container with white background -->
        <div class="bg-white rounded-lg shadow-md w-11/12 md:w-auto h-auto max-h-[97vh] overflow-auto flex md:flex-row flex-col relative">      
          
          <!-- Closing cross for large screens -->
          <div class="hidden md:block absolute top-0 right-0 md:mt-2 md:mr-2 cursor-pointer" @click="closeUploadPhotosModal">
            <svg class="w-[25px]" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M23.3542 7.54102L8.4375 22.4578" stroke="#1F2937" stroke-width="1.24306" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M8.4375 7.54102L23.3542 22.4578" stroke="#1F2937" stroke-width="1.24306" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
  
          <!--Buttons/tabs and closing cross  -->
          <div class="flex flex-col w-full">
            <div class="flex flex-row mr-4 mt-4 ml-5">
              
              <!-- Show create-new-album card button -->
              <div class="flex flex-row justify-end mr-4 border border-slate-200 rounded-md text-black ">
                <button @click="toggleNewAlbumForm" class="rounded-md" :class="[showNewAlbumForm ? 'bg-[#FDD835] ' : 'bg-white text-black', 'text-xs font-semibold py-3 px-6']">
                  Create New
                </button>
              </div>
  
              <!-- Show Add-to-existing-album card button -->
              <div class="flex flex-row justify-end border border-slate-200 rounded-md mr-4 text-black">
                <button @click="toggleExistingAlbumForm" class="rounded-md" :class="[showExistingAlbumForm ? 'bg-[#FDD835]' : 'bg-white ', 'text-xs font-semibold py-3 px-6']">
                  Add to Existing
                </button>
              </div>
  
              <!-- Closing cross for phone screens -->
              <div class="md:hidden mt-2 cursor-pointer" @click="closeUploadPhotosModal">
                <svg class="w-[22px] md:w-[25px]" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M23.3542 7.54102L8.4375 22.4578" stroke="#1F2937" stroke-width="1.24306" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M8.4375 7.54102L23.3542 22.4578" stroke="#1F2937" stroke-width="1.24306" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </div>
  
            <!-- Content based on selected tab -->
            <div class="p-4">

              <div v-if="showNewAlbumForm">
                <h2 class="text-xl font-semibold mb-4">Create New Album</h2>
                <!-- Form for creating a new album -->
                
                <!-- Create-New-Album card -->
                <form :class="[showNewAlbumForm ? 'block' : 'hidden', 'md:block']" @submit.prevent="handleSubmit">
                    <div class="flex flex-row gap-4 mt-4">
                        
                        <div class="flex flex-col gap-2 p-2 rounded max-w-[100%]">
                            
                            <UppyUploader :instanceId="'new-album'" :startUpload="shouldStartUpload" :formData="newAlbumFormData" :toggleUploadStatus="toggleUploadStatus" 
                              @close-component="closeUploadPhotosModal" @uploadComplete="handleUploadComplete" @uploadError="handleUploadError">
                            </UppyUploader>

                            <!-- Autocomplete Labels Drop drown -->
                            <AutoCompleteDropDown :initialTags="selectedTags" @update-tags="handleUpdateTags"></AutoCompleteDropDown>
                
                            <!-- Description -->
                            <div class="border border-slate-200 rounded-md">
                                <textarea v-model="description" style="border: 0px" class="w-full text-xs md:text-sm p-2 h-20" id="description" placeholder="Description"></textarea>
                            </div>

                            <!-- Manual date -->
                            <div class="border border-slate-200 rounded-md">
                                <input type="date" step="any" v-model="manualDate" style="border: 0px" class="w-full text-xs md:text-sm p-2" id="date" placeholder="Date"></input>
                            </div>

                        
                            <!-- Facilitator -->
                            <!-- Educators Select -->
                            <!-- <div class="flex flex-row items-center justify-between mb-1.5 border border-slate-200 rounded-lg">        
                              
                                <div class="relative w-[100%] md:w-[100%]">
                                    <select v-model="selectedEducator" id="educatorsSelect" ref="educatorsSelect" data-hs-select='{
                                            "placeholder": "Facilitator",
                                            "toggleTag": "<button type=\"button\"></button>",
                                            "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-2 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm font-thin text-slate-500 focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                                            "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                                            "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                                            "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                                        }' class="hidden">
                                    <option v-for="educator in educatorsOptions" :key="educator.name" :value="educator.name">{{ educator.educator_name }}</option>
                                    </select>
                                    <div class="absolute top-1/2 end-3 -translate-y-1/2">
                                        <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m7 15 5 5 5-5"/><path d="m7 9 5-5 5 5"/></svg>
                                    </div>
                                </div>

                            </div>   -->
                            <!-- End Select -->
                            
                            <!-- Loading message -->
                            <!-- <div v-if="isLoading" class="text-green-500">Please wait until this is done</div> -->
                            <div v-if="backgroundProcessStarted" class="text-green-500">Great! you may close this page</div>
                            
                            <!-- Response message -->
                            <div v-if="responseMessage" :class="responseMessage.includes('Photos uploaded successfully!') ? 'text-green-500' : 'text-red-500'">
                            {{ responseMessage }}
                            </div>
                            <!-- Submit button -->
                            <div class="flex felx-row justify-end">
                                <button class="w-1/4 text-xs md:text-s font-bold rounded py-2.5 px-2 text-white bg-black">
                                    {{ isLoading ? 'Processing...' : 'Submit' }}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>     

              </div>

              <div v-if="showExistingAlbumForm">

                <div v-if="!showNextPageForExistingAlbum">
                  <h2 class="text-xl font-semibold mb-4">Add to an Existing Album</h2>

                  <!-- Album Card with images and data-->
                   <template v-for="(key, index) in Object.keys(props.existingAlbumsData).filter(key => props.existingAlbumsData[key].image_count > 0)
                         .slice(0, 3)" :key="index">
                    {{ console.log("P:this is existing album data--",props.existingAlbumsData) }}
                      <div v-if="props.existingAlbumsData[key].image_count > 0" 
                      class="flex flex-row border border-slate-200 p-2 w-[100%] md:w-auto md:max-w-[20.5rem] rounded-xl mt-2"
                        :class="{ 'ring-2 ring-blue-500': selectedAlbumId === props.existingAlbumsData[key].week }"
                        @click="handleAlbumClick(props.existingAlbumsData[key])">
                        
                        <!-- Album Images and Data -->
                        <div class="flex flex-col border-slate-200 rounded-md items-center pl-[25px] md:p-2">
                          <div class="flex flex-wrap relative min-w-[7rem] items-center">
                            <img v-if="props.existingAlbumsData[key].first_three_images[0]" :src="props.existingAlbumsData[key].first_three_images[0].thumbnail_url"
                              class="rounded-md" style="height: 100px !important;" width="100" />
                          </div>
                        </div>

                        <div> 
                          <!-- Date and Image count -->
                          <div class="font-medium m-2 text-md">{{formatWeekRange(props.existingAlbumsData[key].week) }}</div>
                          <div class="text-slate-500 font-medium text-xs">{{ props.existingAlbumsData[key].image_count }} Photos</div>
                        </div>
                      </div>
                   </template>

                  <!-- Next button to upload to the selected album -->
                  <div class="flex flex-row justify-end mt-4">
                    <button @click="handleNextUploadPageClick" class="w-1/4 text-xs md:text-s font-bold rounded py-2.5 px-3 text-white bg-black">
                      Next
                    </button>
                  </div>
                </div> 
              </div>

          
              <!-- This section appears after clicking Next -->
              <div v-if="showExistingAlbumForm && showNextPageForExistingAlbum">
                <h2 class="text-xl font-semibold mb-4">Add to an Existing Album</h2>
                
                <!-- Form similar to New Album with preloaded data -->
                <form @submit.prevent="handleUploadToExistingFormSubmit">
                  <div class="flex flex-col gap-4 mt-4">
                    

                    <UppyUploader :instanceId="'existing-album'" :startUpload="shouldStartUpload" :formData="existingAlbumFormData" :toggleUploadStatus="toggleUploadStatus" 
                      @close-component="closeUploadPhotosModal" @uploadComplete="handleUploadComplete" @uploadError="handleUploadError">
                    </UppyUploader>


                    <!-- Autocomplete Labels Drop drown -->
                    <AutoCompleteDropDown :initialTags="selectedAlbumData.labels" @update-tags="handleUpdateTags"></AutoCompleteDropDown>

                    <!-- Description -->
                    <div class="border border-slate-200 rounded-md">
                      <textarea v-model="selectedAlbumData.description" style="border: 0px" class="w-full text-xs md:text-sm p-2 h-20"
                        placeholder="Description"></textarea>
                    </div>

                    <!-- Manual date -->
                    <div class="border border-slate-200 rounded-md">
                      <input type="date" v-model="selectedAlbumData.manualDate" style="border: 0px" class="w-full text-xs md:text-sm p-2"
                        placeholder="Date"></input>
                    </div>

                    <!-- Facilitator (Educators Select) -->
                    <!-- <div class="flex flex-row items-center justify-between mb-1.5 border border-slate-200 rounded-lg">        

                      <div class="relative w-[100%] md:w-[100%]">
                        <select v-model="selectedAlbumData.selectedEducator" id="educatorsSelect" ref="educatorsSelect" data-hs-select='{
                            "placeholder": "Facilitator",
                            "toggleTag": "<button type=\"button\"></button>",
                            "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-2 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm font-thin text-slate-500 focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                            "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                            "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                            "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                          }' class="hidden">
                          <option v-for="educator in educatorsOptions" :key="educator.name" :value="educator.name">
                            {{ educator.educator_name }}
                          </option>
                        </select>
                        <div class="absolute top-1/2 end-3 -translate-y-1/2">
                            <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m7 15 5 5 5-5"/><path d="m7 9 5-5 5 5"/></svg>
                        </div>
                      </div>
                    </div> -->

                    <!-- Submit Button -->
                    <div class="flex justify-end">
                      <button class="w-1/4 text-xs md:text-s font-bold rounded py-2.5 px-2 text-white bg-black">
                        {{ isLoading ? 'Processing...' : 'Submit' }}
                      </button>
                    </div>
                  </div>
                </form>
              </div>          
            </div>


          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
 
  import { ref, onMounted, inject, computed, watch } from 'vue';
  import { defineEmits } from 'vue'
  import AutoCompleteDropDown from '../photoEssays/AutoCompleteDropDown.vue';
  import UppyUploader from './uppyUploader.vue';
  import moment from 'moment';
  


  const props = defineProps({
    optionsResponse: Object,
    defaultTab: String,
    toggleUploadStatus: {
    type: Function,
    required: true
  },
  existingAlbumsData: {
      type: Object,
      required: true,
    },
  });

  

  const emit = defineEmits(['close-modal'])
  
  const isModalOpen = ref(true);
  const showNewAlbumForm = ref(true);
  const showExistingAlbumForm = ref(false);

  const educatorsOptions = ref([])
  const shouldStartUpload = ref(false);


  const description = ref('');
  const manualDate = ref('');
  // const educatorsSelect = ref('');
  const selectedTags = ref([])

  const formData = ref({});
  const newAlbumFormData = ref({});
  const existingAlbumFormData = ref({});

  
  const selectedAlbumId = ref(null);
  const selectedAlbumData = ref({});
  const showNextPageForExistingAlbum = ref(false);




  onMounted(() => {
    assignOptionsData();

    // Preload data for New Album form
    if (window.newAlbumFormData) {
      console.log("W:THis is data in new window form upload",window.newAlbumFormData)
      manualDate.value = window.newAlbumFormData.manualDate || '';
      selectedTags.value = window.newAlbumFormData.tags ? window.newAlbumFormData.tags.split(',') : [];
      // educatorsSelect.value = { value: window.newAlbumFormData.selectedEducator || '' };
      // console.log("AT:This is  selected Educators value",educatorsSelect.value)
    }

    if (props.defaultTab === 'create-new') {
      toggleNewAlbumForm(); // Open the "Create New" tab
    } else if (props.defaultTab === 'add-to-existing') {
      toggleExistingAlbumForm(); // Open the "Add to Existing" tab
    }
  });



  // Watchers for New Album form
  watch(manualDate, (newDate) => {
    window.newAlbumFormData = window.newAlbumFormData || {};
    window.newAlbumFormData.manualDate = newDate;
  });

  watch(selectedTags, (newTags) => {
    console.log("Watcher triggered: New tags", newTags);
    window.newAlbumFormData = window.newAlbumFormData || {};
    window.newAlbumFormData.tags = newTags.join(',');
  }, { deep: true }); // Add deep: true to detect changes inside the array


  // watch(educatorsSelect.value, (newEducator) => {
  //   console.log("change in educator select detedcted")
  //   window.newAlbumFormData = window.newAlbumFormData || {};
  //   window.newAlbumFormData.selectedEducator = newEducator;
  //   console.log("AT:This is  selected Educators value in Watcher",newEducator)
  // }, { deep: true }); // Add deep: true to detect changes inside the array)




const formatWeekRange = (weekString)=> {
      if (!weekString) {
        return '';
      }
      const [year, weekNumber] = weekString.split('-');
      if (!year || !weekNumber) {
        return ' Undefined';
      }

      // Check each step to isolate the issue
      let date = moment().year(year);
      date = date.week(weekNumber);
      const startOfWeek = date.startOf('isoWeek');
      const endOfWeek = moment(startOfWeek).endOf('isoWeek');

      const formattedStart = startOfWeek.format('D');
      const formattedEnd = endOfWeek.format('Do MMMM');

      return `${formattedStart}-${formattedEnd}`;
    }


  const handleAlbumClick = (albumData) => {
    console.log("R:this is ther album data passed",albumData)
    console.log("R:this is existing album data value",props.existingAlbumsData)

    // Ensure that first_three_images and tags exist before trying to map them
  const tags = albumData.first_three_images?.[0]?.tags?.map(tag => tag.name) || [];
  const customTags = albumData.first_three_images?.[0]?.custom_tags
    ? albumData.first_three_images[0].custom_tags.split(',').map(tag => tag.trim())
    : [];

  selectedAlbumData.value = {
    images: albumData.first_three_images.map(image => image.thumbnail_url),
    labels: [...tags, ...customTags],
    description: albumData.first_three_images[0]?.description,
    image_count: albumData.image_count,
    manualDate:albumData.date_range  ? moment(albumData.date_range.split(' - ')[0], 'MMMM DD, YYYY').format('YYYY-MM-DD') 
    : '',
    // selectedEducator: albumData.first_three_images[0]?.educator || '', // Preload educator
  };
  console.log("R:this is album data on album click",selectedAlbumData.value)
  selectedAlbumId.value = albumData.week;
};



const handleNextUploadPageClick = () => {
  if (Object.keys(selectedAlbumData.value).length > 0) {
    existingAlbumFormData.value = {
      tags: selectedAlbumData.value.labels.join(','),
      description: selectedAlbumData.value.description,
      manualDate: selectedAlbumData.value.manualDate, // Preload the manual date
      // selectedEducator: selectedAlbumData.value.selectedEducator, // Preload the facilitator
    };
    console.log("R:this is manual date being preloaded:",selectedAlbumData.value)
    showNextPageForExistingAlbum.value = true; // Show the next page
    setTimeout(() => {
      window.HSStaticMethods.autoInit(); //Preline is initialised
      
    }, 100);
  } else {
    alert("Please select an album.");
  }
};


  const assignOptionsData = ()=>{
    if (!props.optionsResponse || !props.optionsResponse.educators) {
        console.error("optionsResponse or educators property is not defined");
        return;
      }
    
    try {
          console.log("assign options data called in new Event form");
         
          educatorsOptions.value = props.optionsResponse.educators

          setTimeout(() => {
            window.HSStaticMethods.autoInit(); //Preline is initialised
          console.log("Preline Initialise inside upload videos to new album form after 100ms delay");
          }, 100)
        
    } 
    catch (error) {
        console.error('Error assigning data:', error);
      }
    }
  
  const toggleNewAlbumForm = () => {
    showNewAlbumForm.value = true;
    showExistingAlbumForm.value = false;
    showNextPageForExistingAlbum.value = false;
    setTimeout(() => {
      window.HSStaticMethods.autoInit(); //Preline is initialised
      console.log("Preline Initialise inside upload videos after button toggle");
    }, 100);
  };
  
  const toggleExistingAlbumForm = () => {
    showExistingAlbumForm.value = true;
    showNewAlbumForm.value = false;
  };
  
  const closeUploadPhotosModal = () => {
    isModalOpen.value = false;
    console.log('Closing upload photos modal');
    emit('close-modal')
  };

  const handleSubmit = () => {
    newAlbumFormData.value = {
      tags: selectedTags.value.join(','),
      description: description.value,
      manualDate: manualDate.value,
      // selectedEducator: educatorsSelect.value.value,
    };
    shouldStartUpload.value = true;
  };


const handleUploadToExistingFormSubmit = () => {
  existingAlbumFormData.value = {
    tags: selectedAlbumData.value.labels.join(','),
    description: selectedAlbumData.value.description,
    manualDate: selectedAlbumData.value.manualDate,
    // selectedEducator: selectedAlbumData.value.selectedEducator,
  };
  shouldStartUpload.value = true;
};


const handleUpdateTags =(tags)=> {
      selectedTags.value = tags;
}



const handleUploadComplete = ({ file, response }) => {
  alert('Upload completed', file, response);
 // Any logic you want to run when upload completes
};

const handleUploadError = ({ file, error, response }) => {
  console.error('Upload error', file, error, response);
  // Any error handling logic
};
  </script>




<template>
  <div class="flex flex-col max-h-[94vh] md:max-h-[91.5vh] pt-6 pb-20 md:pb-6 lg:pb-8 px-2 lg:py-8 lg:px-16 
    gap-4 lg:gap-8 lg:pr-28 overflow-y-auto">
    <h2 class="text-3xl font-normal">
      Observation Statistics
    </h2>
    <div class="flex md:flex-row gap-2 md:gap-8 md:items-center">
      <AddNewDropdown :isSmall="true" />
      <router-link to="/front-portal/observations">
        <button class="py-3 px-4 inline-flex items-center gap-x-2 text-xs
          md:text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
          hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit"
          type="button">
          <span class="hidden md:inline">View </span>Observations
        </button>
      </router-link>
      <router-link to="/front-portal/observations/summaries">
        <button class="py-3 px-4 inline-flex items-center gap-x-2 text-xs
          md:text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
          hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit"
          type="button">
          Summaries
        </button>
      </router-link>
    </div>
    <div class="flex flex-col lg:flex-row items-start lg:items-center gap-2 lg:gap-8">
      <h3 class="text-xl font-medium">
        This Week at a Glance
      </h3>
            <div class="flex">
              <button type="button" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium
                rounded-l-lg border border-transparent bg-[#FDD835] text-black hover:bg-[#fef3c2]
                focus:outline-none focus:bg-[#fef3c2] disabled:opacity-50 disabled:pointer-events-none"
                @click="() => { changeWeek(-1) }"
                >
                <span><</span>
              </button>
              <div class="bg-[#FDD835] text-gray-900 px-8 flex justify-center items-center font-medium
                text-xs lg:text-sm py-3">
                {{ formatDateWithSuffixShort(weekdays[0]) }} - {{ formatDateWithSuffixShort(weekdays[4]) }}
              </div>
              <button type="button" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium
                rounded-r-lg border border-transparent bg-[#FDD835] text-black hover:bg-[#fef3c2]
                focus:outline-none focus:bg-[#fef3c2] disabled:opacity-50 disabled:pointer-events-none
                flex gap-2"
                @click="() => { changeWeek(1) }"
                >
                <span>></span>
              </button>
            </div>
    </div>
    <div class="grid lg:grid-cols-5 w-full gap-8 items-start">
      <Card class="lg:col-span-3">
        <h5 class="underline font-medium underline-offset-4 decoration-2">
          Total Observations Added
        </h5>
        <div class="flex flex-col lg:flex-row gap-2" v-if="weeklyResource.data?.total_counts">
          <ObservationProgress observationType="activity" 
            :count="weeklyResource.data.total_counts.actual_counts.activity_observations"
            :totalCount="weeklyResource.data.total_counts.max_possible.activity_observations"
             />
          <ObservationProgress observationType="child" 
            :count="weeklyResource.data.total_counts.actual_counts.child_observations"
            :totalCount="weeklyResource.data.total_counts.max_possible.child_observations"
             />
          <ObservationProgress observationType="weekly" 
            :count="weeklyResource.data.total_counts.actual_counts.weekly_child_observations"
            :totalCount="weeklyResource.data.total_counts.max_possible.weekly_child_observations"
             />
        </div>
      </Card>
      <Card class="lg:col-span-2 items-center">
        <h5 class="underline font-medium underline-offset-4 decoration-2">
          Who added the most observations?
        </h5>
        <div class="h-96 w-[95%] md:w-full">
          <BarChart :categories="educatorNames" :values="educatorCounts" :color="educatorsColors" 
            :inverse="true" :showYTick="false" />
        </div>
      </Card>
      <Card class="lg:col-span-2">
        <h5 class="underline font-medium underline-offset-4 decoration-2">
          Learners without observations this week
        </h5>
        <StudentObservationCounts type="week" :countList="studentObservationCounts"
          :cohortList="filteredCohorts" />
      </Card>
      <Card class="lg:col-span-3" v-if="unansweredList.length">
        <div class="flex flex-col gap-6">
          <h5 class="underline font-medium underline-offset-4 decoration-2">
            Most Skipped/Unanswered Questions
          </h5>
          <UnansweredQuestions :unansweredList="unansweredList" />
        </div>
      </Card>
    </div>
  </div>
</template>

<script setup>
import { createResource } from 'frappe-ui';
import WeeklyStats from './WeeklyStats.vue';
import MonthlyStats from './MonthlyStats.vue';
import { inject, computed, ref, onMounted } from 'vue';
import { 
  getTodayDateJSFormat, goBackTwoDays, 
  getDateFromPreviousMonth, atLeastTwoMondaysAndFridaysPassed,
  formatDateWithSuffixShort, getWeekdays, getLastWeekDate,
  getNextWeekDate
} from '@/lib/utils'
import AddNewDropdown from '@/components/observations/forms/AddNewDropdown.vue';
import Card from '@/components/ui/Card.vue'
import BarChart from '../../charts/BarChart.vue';
import ObservationProgress from './ObservationProgress.vue';
import StudentObservationCounts from './StudentObservationCounts.vue';
import UnansweredQuestions from './UnansweredQuestions.vue';
import { cohortsIgnoreList } from '../../../lib/filterData';

const today = ref(getTodayDateJSFormat())
const hasTwoWeeksPast = computed(() => atLeastTwoMondaysAndFridaysPassed(today.value))

console.log("Summary today", today)
const weekdays = computed(() => getWeekdays(today.value))
console.log("Summary weekdays", weekdays)

const weeklyResource = createResource({
  'url': 'curriculum_planner.curriculum_planner.common.observations.stats.overview.api.get_weekly_stats',
  params: {
    date: today.value,
  },
  // auto: true,
})

const monthlyResource = createResource({
  'url': 'curriculum_planner.curriculum_planner.common.observations.stats.count.api.get_monthly_stats',
  params: {
    date: today.value,
  },
  // auto: true,
})

// console.log(weeklyResource)

const weekDataArray = ref([])
const monthDataArray = ref([])

onMounted(async () => {
  await weeklyResource.fetch()
  // await monthlyResource.fetch()

  if (weeklyResource.data?.status === 'success')
    console.log("Weekly resource", weeklyResource.data)
    weekDataArray.value = weeklyResource.data.week_list
  // if (monthlyResource.data?.status === 'success')
  //   monthDataArray.value = monthlyResource.data.month_list
})

const currentWeek = ref(1)
const currentMonth = ref(hasTwoWeeksPast ? 0 : 1)

const changeWeek = (difference) => {
  if (difference === 1) {
    const newToday = getNextWeekDate(today.value);
    today.value = newToday;
    weeklyResource.submit({ date: newToday })
  } else if (difference === -1) {
    const newToday = getLastWeekDate(today.value);
    today.value = newToday;
    weeklyResource.submit({ date: newToday })
  }
  console.log(weeklyResource)
}

const educatorsColors = ['#FFC000', '#F58F00', '#EC681B', '#FE965C', '#FFEBAE']

const goToPrevWeek = async () => {
  if (!weekDataArray.value[currentWeek.value+2]) {
    const endWeek = weekDataArray.value[currentWeek.value+1]
    const nextDate = goBackTwoDays(endWeek.start_date)
    await weeklyResource.submit({ date: nextDate})
    if (weeklyResource.data?.status === 'success') {
      weeklyResource.data.week_list.forEach((weekData) => {
        weekDataArray.value.push(weekData)
      })
    }
  }
  currentWeek.value++
}

const goToNextWeek = () => {
  currentWeek.value--
}

const goToPrevMonth = async () => {
  if (!monthDataArray.value[currentMonth.value+2]) {
    const endMonth = monthDataArray.value[currentMonth.value+1]
    const startDate = endMonth.start_date
    const nextDate = getDateFromPreviousMonth(startDate)
    await monthlyResource.submit({ date: nextDate})
    if (monthlyResource.data?.status === 'success') {
      monthlyResource.data.month_list.forEach((monthData) => {
        monthDataArray.value.push(monthData)
      })
    }
  }
  currentMonth.value++
}

const goToNextMonth = () => {
  currentMonth.value--
}

const userDetails = inject('userDetails')

const cohortList = ['Bombils', 'Batatas-1', 'Batatas-2', 'Bimblis', 'BigKids']

const filteredCohorts = computed(() => {
  if (userDetails.value?.school_info.cohorts) {
    const filteredSortedArray = userDetails.value.school_info.cohorts.filter((cohort) => {
      return !cohortsIgnoreList.includes(cohort.cohort_name)
    // }).sort((cohortA, cohortB) => {
    //   return cohortList.indexOf(cohortA.cohort_name) - cohortList.indexOf(cohortB.cohort_name)
    })
    filteredSortedArray.unshift({ name: "all", cohort_name: "All Students" })
    return filteredSortedArray
  }
  return []
})

function roundUpToNearestFive(number) {
  return Math.ceil(number / 5) * 5;
}

const educatorNames = computed(() => {
  if (!weeklyResource.data?.educator_counts) return []
  return weeklyResource.data.educator_counts.educator_stats.map((stat) => stat.educator_name)
})

const educatorCounts = computed(() => {
  if (!weeklyResource.data?.educator_counts) return []
  return weeklyResource.data.educator_counts.educator_stats.map((stat) => stat.total_observations)
})

const studentObservationCounts = computed(() => {
  if (!weeklyResource.data?.student_counts) return []
  return weeklyResource.data?.student_counts;
})

const unansweredList = computed(() => {
  if (!weeklyResource.data?.unanswered_counts) return [];
  return weeklyResource.data.unanswered_counts;
})
</script>